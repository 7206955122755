import { defineMessages } from "react-intl";

export default defineMessages({
  selected: {
    id: "ParametersInterestMessages.selected",
    defaultMessage: "{value} Selected",
  },
  panel_height: {
    id: "ParametersInterestMessages.panel_height",
    defaultMessage: "Table Height",
  },
  panel_opacity: {
    id: "ParametersInterestMessages.panel_opacity",
    defaultMessage: "Panel Opacity",
  },
  panel_size: {
    id: "ParametersInterestMessages.panel_size",
    defaultMessage: "Table Size",
  },
  initial_offset: {
    id: "ParametersInterestMessages.initial_offset",
    defaultMessage: "Panel Offset",
  },
  panels_number: {
    id: "ParametersInterestMessages.panels_number",
    defaultMessage: "Number of tables",
  },
  panels_gap: {
    id: "ParametersInterestMessages.panels_gap",
    defaultMessage: "Space Between Panels",
  },
  field_size: {
    id: "ParametersInterestMessages.field_size",
    defaultMessage: "Field Size",
  },
  azimuth: {
    id: "ParametersInterestMessages.azimuth",
    defaultMessage: "Azimuth",
  },
  crop_id: {
    id: "ParametersInterestMessages.crop_id",
    defaultMessage: "Crop",
  },
  weather_dataset_id: {
    id: "ParametersInterestMessages.weather_dataset_id",
    defaultMessage: "Weather Dataset",
  },
  ETP_dataset_id: {
    id: "ParametersInterestMessages.ETP_dataset_id",
    defaultMessage: "ETP Dataset",
  },
  production_dataset_id: {
    id: "ParametersInterestMessages.production_dataset_id",
    defaultMessage: "Production Dataset",
  },
  steering_algorithm_id: {
    id: "ParametersInterestMessages.steering_algorithm_id",
    defaultMessage: "Steering Algorithm",
  },
  search: {
    id: "ParametersInterestMessages.search",
    defaultMessage: "Search",
  },
});
