import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as Warning } from "../../assets/icons/warning.svg";
import { ReactComponent as Information } from "../../assets/icons/information.svg";
import styled from "./styled.module.scss";
import { getInitials } from "utils/getInitials";
import CustomToggle from "components/CustomToggle/CustomToggle";
import { ReactComponent as Settings } from "assets/icons/settings.svg";
import { ReactComponent as User } from "assets/icons/user.svg";
import { ReactComponent as Logout } from "assets/icons/log-out.svg";
import { ReactComponent as Delete } from "assets/icons/cross-circle.svg";
import { ReactComponent as Eye } from "assets/icons/eyes-open.svg";
import { ReactComponent as Add } from "assets/icons/plus-circle.svg";
import { ReactComponent as World } from "assets/icons/world.svg";
import profileMessages from "components/Profile/ProfileMessages";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { auth, editProfile, logout, updateAsSeenAlert } from "store/user/actions";
import { getUserDataState } from "store/user/selectors";
import { common } from "@mui/material/colors";
import CommonMessages from "components/common/CommonMessages";
import Button from "components/common/button/Button";

import { ReactComponent as FR } from "assets/icons/fr.svg";
import { ReactComponent as IT } from "assets/icons/it.svg";
import { ReactComponent as US } from "assets/icons/us.svg";
import clsx from "clsx";
import { Languages } from "translations";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import Select from "components/common/select/Select";
import { Formik, useFormik } from "formik";
import { createAlert, deleteAlert, seeAlert } from "store/alert/api";
import { successNotifications } from "utils/successNotifications";
import { errorNotifications } from "utils/errorNotifications";
import ModalUserAlert from "./ModalUserAlert";


const Profile = () => {
  const intl = useIntl()
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { client_id } = useParams();
  const user = useSelector(getUserDataState);

  const [modalOpen, setModalOpen] = useState(false)
  const [modalAlertOpen, setModalAlertOpen] = useState(false)
  const [modalUsersAlert, setModalUsersAlert] = useState(false)
  const [languageSelected, setLanguageSelected] = useState(user ? user.language : Languages.EN)

  const alertTypeOptions = [{
    label: "Deploy",
    value: "deploy"
  }, {
    label: "Warning",
    value: "warning"
  }, {
    label: "Information",
    value: "information"
  }]

  const alertFormik = useFormik({
    initialValues: {
      type: "deploy",
      text: "",
    },
  })

  useEffect(() => {
    if (!user) dispatch(auth());
    else setLanguageSelected(user.language)
  }, [user]);

  const onSaveLanguage = () => {
    dispatch(
      editProfile(
        { language: languageSelected }
      )
    )
  }

  const onSettingsClick = () => {
    navigate(ROUTES.SETTING_USERS.replace(":client_id", client_id));
  };

  const onOpenLanguageModal = () => {
    setModalOpen(true)
  }
  const onCloseLanguageModal = () => {
    setModalOpen(false)
  }

  const onOpenAlertModal = () => {
    setModalAlertOpen(true)
  }
  const onCloseAlertModal = () => {
    setModalAlertOpen(false)
  }

  // useEffect(() => {
  //   if (user && !user.as_seen_alert)
  //     onOpenUserAlertModal()
  // }, [user])

  const onOpenUserAlertModal = () => {
    setModalUsersAlert(true)
  }
  const onCloseUserAlertModal = () => {
    setModalUsersAlert(false)
  }

  const onCreateAlert = () => {
    createAlert({ ...alertFormik.values }).then(() => {
      successNotifications({
        title: intl.formatMessage(CommonMessages.create),
        message: intl.formatMessage(CommonMessages.successCreate, {
          objet_type: 'Alert',
        }),
      });
      onCloseAlertModal()
    }).catch(({ response }) => errorNotifications(response.data.data))
  }

  const onDeleteAlert = () => {
    deleteAlert().then(() => {
      successNotifications({
        title: intl.formatMessage(CommonMessages.delete),
        message: intl.formatMessage(CommonMessages.successDelete, {
          objet_type: 'Alert',
        }),
      });
    }).catch(({ response }) => errorNotifications(response.data.data))
  }

  const onLogoutClick = async () => {
    dispatch(logout(() => navigate(ROUTES.LOGIN)))
  };



  if (!user) return null;

  return (
    <>
      <Dropdown className={styled.dropdown}>
        <Dropdown.Toggle as={CustomToggle}>
          <div className={styled.container}>
            <div className={styled.textContainer}>
              <h4 className={styled.name}>{user.full_name}</h4>
              <span className={styled.email}>{user.email}</span>
            </div>

            <span className={styled.initials}>{getInitials(user.full_name || 'new user')}</span>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu align="end">
          <Dropdown.Item
            as={Link}
            className={styled.dropdownItem}
            to={ROUTES.PROFILE}
            state={{ background: location }}
          >
            <User /> <FormattedMessage {...profileMessages.myProfile} />
          </Dropdown.Item>
          {!!client_id && (
            <Dropdown.Item
              className={styled.dropdownItem}
              onClick={onSettingsClick}
            >
              <Settings /> <FormattedMessage {...profileMessages.settings} />
            </Dropdown.Item>
          )}

          {/* <Dropdown.Divider className={styled.dropdownDivider} />

          <Dropdown.Item
            className={styled.dropdownItem}
            onClick={onOpenLanguageModal}
          >
            <World /> <FormattedMessage {...profileMessages.language} />
          </Dropdown.Item> */}

          {user.role.name == 'admin' && <>
            <Dropdown.Divider className={styled.dropdownDivider} />
            <Dropdown.Item className={styled.dropdownItem} onClick={onOpenAlertModal}>
              <Add /> <FormattedMessage {...profileMessages.addAlert} />
            </Dropdown.Item>
            <Dropdown.Item className={styled.dropdownItem} onClick={onDeleteAlert}>
              <Delete /> <FormattedMessage {...profileMessages.deleteAlert} />
            </Dropdown.Item>
          </>
          }
          <Dropdown.Divider className={styled.dropdownDivider} />
          <Dropdown.Item className={styled.dropdownItem} onClick={onOpenUserAlertModal}>
            <Eye /> <FormattedMessage {...profileMessages.seeAlert} />
          </Dropdown.Item>
          <Dropdown.Divider className={styled.dropdownDivider} />

          <Dropdown.Item className={styled.dropdownItem} onClick={onLogoutClick}>
            <Logout /> <FormattedMessage {...profileMessages.logout} />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Modal show={modalOpen} >
        <Modal.Header>
          <Modal.Title> <FormattedMessage {...profileMessages.language} /> </Modal.Title>
        </Modal.Header>

        <div className={styled.selector}>
          <div className={clsx(styled.icon, { [styled.selected]: languageSelected == Languages.FR })} onClick={() => setLanguageSelected(Languages.FR)}>
            <FR />
          </div>
          <div className={clsx(styled.icon, { [styled.selected]: languageSelected == Languages.IT })} onClick={() => setLanguageSelected(Languages.IT)}>
            <IT />
          </div>
          <div className={clsx(styled.icon, { [styled.selected]: languageSelected == Languages.EN })} onClick={() => setLanguageSelected(Languages.EN)}>
            <US />
          </div>

        </div>

        <Modal.Footer>
          <Button onClick={onCloseLanguageModal}>
            <FormattedMessage {...CommonMessages.close} />
          </Button>
          <Button onClick={onSaveLanguage}>
            <FormattedMessage {...CommonMessages.save} />
          </Button>
        </Modal.Footer>

      </Modal>
      <Modal show={modalAlertOpen} >
        <Modal.Header>
          <Modal.Title> <FormattedMessage {...profileMessages.addAlert} /> </Modal.Title>
        </Modal.Header>

        <div className={styled.alertContainer}>
          <Select
            label={"Select alert type"}
            options={alertTypeOptions}
            value={alertTypeOptions.find(val => val.value == alertFormik.values.type)}
            onChange={(e) => {
              alertFormik.setFieldValue("type", e.value);
            }}
          />
          <div className={clsx(styled.preview, styled[alertFormik.values.type])}>
            <div className={styled.titleContainer}>
              <div className={styled.bigIcon}>
                {alertFormik.values.type == "warning" ? <Warning /> : <Information />}
              </div>
              {alertTypeOptions.find(val => val.value == alertFormik.values.type).label}
            </div>
            <div className={styled.text}>
              <textarea onChange={(e) => { alertFormik.setFieldValue("text", e.target.value); }} />
            </div>
          </div>
        </div>
        <Modal.Footer>
          <Button onClick={onCloseAlertModal}>
            <FormattedMessage {...CommonMessages.close} />
          </Button>
          <Button onClick={onCreateAlert}>
            <FormattedMessage {...CommonMessages.create} />
          </Button>
        </Modal.Footer>

      </Modal>

      <ModalUserAlert show={modalUsersAlert} onClose={() => { onCloseUserAlertModal(); seeAlert().then(() => { dispatch(auth()) }) }} />
    </>
  );
};

export default Profile;
