import { apiGET, apiPOST, apiDELETE, apiPATCH } from "store/services/api";

export const createAlert = ({ ...data }) =>
  apiPOST({
    url: `alerts/create`,
    data,
  });

export const deleteAlert = () =>
  apiDELETE({
    url: `alerts/delete`,
  });

export const getAlert = () =>
  apiGET({
    url: `alerts`,
  });

export const seeAlert = () =>
  apiGET({
    url: `alerts/see`,
  });
