export const getDirectoryState = (state) => state.directorys;
export const getDirectoryListState = (state) => getDirectoryState(state).list;
export const getDirectoryLoadingState = (state) =>
  getDirectoryState(state).isLoading;
export const getDirectorySortState = (state) => getDirectoryState(state).sort;

export const getDirectoryById = (directoryId) => (state) => {
  const list = getDirectoryListState(state);
  return list.find(({ id }) => id.toString() === directoryId?.toString());
};
