import { defineMessages } from "react-intl";

export default defineMessages({
  day_stress_thermique: {
    id: "DatasetAnalisysMessages.day_stress_thermique",
    defaultMessage: "Number of Days in Thermal Stress (T > 25°C)",
  },
  deficit_hydrique: {
    id: "DatasetAnalisysMessages.deficit_hydrique",
    defaultMessage: "Water Deficit (mm)",
  },
  total_precipitation: {
    id: "DatasetAnalisysMessages.total_precipitation",
    defaultMessage: "Annual Precipitation (mm)",
  },
  day_intense_precipitation: {
    id: "DatasetAnalisysMessages.day_intense_precipitation",
    defaultMessage: "Number of Days with Intense Precipitation (> 25mm)",
  },
  annual_mean_tempeture: {
    id: "DatasetAnalisysMessages.annual_mean_tempeture",
    defaultMessage: "Annual Mean Temperature (°C)",
  },
});
