import {
  getDirectoryList,
  createDirectory,
  editDirectory,
  deleteDirectory,
  linkProject,
} from "store/directorys/actions";
import { logout } from "store/user/actions";

const INITIAL_STATE = {
  isLoading: false,
  list: [],
};

const directorysReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case logout.SUCCESS: {
      return {
        ...INITIAL_STATE,
      };
    }

    case getDirectoryList.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case getDirectoryList.SUCCESS: {
      return {
        ...state,
        list: payload,
        isLoading: false,
      };
    }

    case createDirectory.SUCCESS: {
      console.log(payload);
      return {
        ...state,
        list: [...state.list, payload],
      };
    }

    case getDirectoryList.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case editDirectory.SUCCESS: {
      const newList = [...state.list];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = payload;

      return {
        ...state,
        list: newList,
      };
    }

    case deleteDirectory.SUCCESS: {
      const newList = [...state.list];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList.splice(index, 1);

      return {
        ...state,
        list: newList,
      };
    }

    case linkProject.SUCCESS: {
      return {
        ...state,
        list: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default directorysReducer;
