import React, { Children, useState } from "react";
import { ReactComponent as Arrow } from "assets/icons/arrow.svg";
import clsx from "clsx";
import styled from "./styled.module.scss";
import { Link } from "react-router-dom";

interface ICustomAccordion {
  title: string;
  to?: string;
  children: any;
  count?: number;
}

const Accordion = ({ title, children, to, count = -1 }: ICustomAccordion) => {
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = () => setIsOpen((prev) => !prev);

  return (
    <div className={styled.container}>
      <div
        className={clsx(styled.heading, {
          [styled.pointer]: !to,
        })}
        {...(!to && { onClick: onToggle })}
      >
        <button
          className={clsx(styled.toggle, {
            [styled.toggleOpen]: isOpen,
          })}
          type="button"
          {...(!!to && { onClick: onToggle })}
        >
          <Arrow />
        </button>
        {!to ? (
          <h3 className={styled.title}>{title}</h3>
        ) : (
          <Link to={to} className={styled.title}>
            {title}
          </Link>
        )}

        <span className={styled.counter}>
          {count < 0 ? Children.count(children) : count}
        </span>
      </div>

      <div
        className={clsx(styled.content, {
          [styled.contentShow]: isOpen,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
