import {
  getCrops,
  createCrop,
  getSingleCrop,
  getSimulationCrop,
  getSingleCopyCrop,
  updateCrop,
  deleteCrop,
  copyCrop,
} from "store/crops/actions";
import { logout } from "store/user/actions";

const INITIAL_STATE = {
  isLoading: false,
  list: [],
  item: {},
};

const cropsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case logout.SUCCESS: {
      return {
        ...INITIAL_STATE,
      };
    }

    case getCrops.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case getCrops.SUCCESS: {
      return {
        ...state,
        list: payload,
      };
    }

    case getCrops.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case copyCrop.TRIGGER:
    case getSingleCrop.TRIGGER:
    case getSimulationCrop.TRIGGER:
    case getSingleCopyCrop.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case getSingleCrop.SUCCESS:
    case getSimulationCrop.SUCCESS:
    case getSingleCopyCrop.SUCCESS: {
      return {
        ...state,
        item: payload,
      };
    }

    case copyCrop.FULFILL:
    case getSingleCrop.FULFILL:
    case getSimulationCrop.FULFILL:
    case getSingleCopyCrop.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case createCrop.SUCCESS: {
      return {
        ...state,
        list: [...state.list, payload.crop],
      };
    }

    case copyCrop.SUCCESS: {
      return {
        ...state,
        list: [...state.list, payload],
      };
    }

    case updateCrop.SUCCESS: {
      const newList = [...state.list];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = payload;

      return {
        ...state,
        list: newList,
      };
    }

    case deleteCrop.SUCCESS: {
      const list = state.list.filter(({ id }) => id !== payload.id);
      return {
        ...state,
        list,
      };
    }

    default: {
      return state;
    }
  }
};

export default cropsReducer;
