import React, { useEffect, useMemo } from "react";
import styled from "./styled.module.scss";
import Profile from "components/Profile/Profile";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { getClientsList, getClientSort } from "store/clients/actions";
import { getProjectsList, getProjectSort } from "store/projects/actions";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TextSelect from "components/common/textSelect/TextSelect";
import {
  getClientsListState,
  getClientsSortState,
} from "store/clients/selectors";
import {
  getProjectsListState,
  getProjectsSortState,
} from "store/projects/selectors";
import { ROUTES } from "constants/routes";
import { getUserDataState } from "store/user/selectors";
import { UserRoles } from "components/SettingUserCard/SettingUserCard";
import { getDirectoryList } from "store/directorys/actions";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { client_id, project_id } = useParams();
  const clients = useSelector(getClientsListState);
  const projects = useSelector(getProjectsListState);
  const clientSort = useSelector(getClientsSortState);
  const projectSort = useSelector(getProjectsSortState);
  const user = useSelector(getUserDataState);
  const isClientList = pathname === ROUTES.CLIENTS_LIST;
  const isAdmin = user?.role.name === UserRoles.ADMIN;

  const clientOptions = useMemo(() => {
    return clients.map(({ id, name }: any) => ({
      label: name,
      value: id,
    }));
  }, [clients]);

  const projectOptions = useMemo(() => {
    return projects.map(({ id, name }: any) => ({
      label: name,
      value: id,
    }));
  }, [projects]);

  useEffect(() => {
    if (isAdmin) dispatch(getClientsList({ sort: clientSort }));
  }, [clientSort, isAdmin]);

  useEffect(() => {
    if (client_id) {
      dispatch(
        getProjectsList({
          clientId: client_id,
          sort: projectSort,
        })
      );
    }
  }, [client_id, projectSort]);

  useEffect(() => {
    if (client_id) {
      dispatch(
        getDirectoryList({
          clientId: client_id,
        })
      );
    }
  }, [client_id]);

  useEffect(() => {
    dispatch(getClientSort());
    dispatch(getProjectSort());
  }, []);

  const onSelectClient = (item: any) => {
    navigate(ROUTES.PROJECTS_LIST.replace(":client_id", item.value));
  };

  const onSelectProject = (item: any) => {
    const path = pathname.split("/");
    path.splice(6);
    path[4] = item.value;

    navigate(path.join("/"));
  };

  return (
    <header className={styled.header}>
      <div className={styled.row}>
        <Link
          to={ROUTES.CLIENTS_LIST}
          {...(isClientList && { reloadDocument: true })}
        >
          <Logo className={styled.logo} />
        </Link>

        {!!client_id && isAdmin && (
          <>
            <div className={styled.vertical} />
            <TextSelect
              options={clientOptions}
              value={clientOptions.find((item: any) => item.value == client_id)}
              onChange={onSelectClient}
              menuAnchor="left"
            />
          </>
        )}
        {!!project_id && (
          <>
            <div className={styled.vertical} />
            <TextSelect
              options={projectOptions}
              value={projectOptions.find(
                (item: any) => item.value == project_id
              )}
              onChange={onSelectProject}
              menuAnchor="left"
            />
          </>
        )}
      </div>

      <Profile />
    </header>
  );
};

export default Header;
