import { useEffect, useState } from "react";
import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  Label,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Text as RechartText,
  ResponsiveContainer,
} from "recharts";
import { default as TooltipMUI } from "@mui/material/Tooltip";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import SimulationResultBodyMessages from "components/SimulationResultBody/SimulationResultBodyMessages";
import CropCreateMessages from "pages/CropsCreate/CropCreateMessages";
import chartToCsv from "utils/chartToCsv";
import chartToPng from "utils/chartToPng";
import CustomTick from "components/CustomTick/CustomTick";
import WaterBalanceResultBodyMessages from "components/WaterBalanceResultBody/WaterBalanceResultBodyMessages";
import moment from "moment";
import WaterBalanceResultMenuMessages from "./WaterBalanceResultMenuMessages";
import { getWaterDeficitGraph, getWaterDeficitTable } from "store/analisys/api";
import { useParams } from "react-router-dom";

class IrradianceContentProps {
  result: any;
  unite: "FRACTION" | "MM" = "FRACTION";
}

const WaterDeficitContent = ({ result, unite }: IrradianceContentProps) => {
  const intl = useIntl();

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className={styled.customTooltip}>
          <p className={styled.label}>
            {intl.formatMessage(CropCreateMessages.stageName)}:{" "}
            {payload[0].payload.period.name
              ? payload[0].payload.period.name
              : "Out of periods."}
          </p>
          <div>
            {payload.map((pld: any) => (
              <div className={styled.flex} id={pld.name}>
                <div>{pld.name} :</div>
                <div style={{ color: pld.fill }}>
                  {pld.value} {pld.unit}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  const {
    client_id,
    project_id,
    water_balance_id,
    id,
    simulation_water_balance_id,
  } = useParams();
  const [tableValues, setTableValues] = useState<any[]>([]);

  useEffect(() => {
    getWaterDeficitTable({
      clientId: client_id,
      projectId: project_id,
      simulationId: water_balance_id,
      id: id || simulation_water_balance_id,
    }).then((res) => setTableValues(res.data));
  }, [client_id, project_id, water_balance_id, id]);

  const [inactiveKeysDailyWD, setInactiveKeyDailyWD] = useState(
    Array<string>()
  );

  if (!tableValues.length) return <div style={{ height: "100vh" }} />;

  return (
    <div className={styled.list}>
      <div className={styled.chartContainer}>
        <div style={{ width: "100%", height: "100%" }}>
          <div className={styled.optionContainer}>
            <div></div>
            <div className={styled.downloadContainer}>
              <TooltipMUI
                title={intl.formatMessage(
                  WaterBalanceResultBodyMessages.hintChartToPng
                )}
              >
                <div
                  className={styled.chartToPngButton}
                  onClick={chartToPng("WaterDeficitChart")}
                >
                  <Icon
                    name={ICON_NAMES_ENUM.download_image}
                    className={styled.file__icon}
                  />
                </div>
              </TooltipMUI>
              <TooltipMUI
                title={intl.formatMessage(
                  WaterBalanceResultBodyMessages.hintChartToCsv
                )}
              >
                <div
                  className={styled.chartToPngButton}
                  onClick={chartToCsv(
                    tableValues,
                    `${result.simulation.name} Water Deficit`
                  )}
                >
                  <Icon
                    name={ICON_NAMES_ENUM.download_file}
                    className={styled.file__icon}
                  />
                </div>
              </TooltipMUI>
            </div>
          </div>
          <div id={"WaterDeficitChart"}>
            <div className={styled.chartTitle}>
              <FormattedMessage
                {...WaterBalanceResultBodyMessages.waterDeficitGraphTitle}
              />
            </div>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart
                width={500}
                height={500}
                data={tableValues.map((data: any) => ({
                  ...data,
                  wd_zt: data.wd_zt,
                  wd_under_panel: data.wd_under_panel,
                  wd_between_panel: data.wd_between_panel,
                  wd_agri_pv: data.wd_agri_pv,
                }))}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  height={75}
                  dataKey={"period.name"}
                  interval={0}
                  tick={<CustomTick />}
                />
                <YAxis>
                  <Label
                    fontSize={12}
                    style={{
                      textAnchor: "middle",
                    }}
                    position={"insideLeft"}
                    angle={270}
                    value={`Water Deficit (mm)`}
                  />
                </YAxis>
                <Tooltip content={CustomTooltip} />
                <Legend
                  style={{ cursor: "pointer" }}
                  onClick={(o) => {
                    if (
                      !inactiveKeysDailyWD.find(
                        (inactiveKey) => inactiveKey == o.dataKey
                      )
                    )
                      setInactiveKeyDailyWD([
                        ...inactiveKeysDailyWD,
                        o.dataKey,
                      ]);
                    else {
                      var temp = inactiveKeysDailyWD;
                      temp.splice(
                        inactiveKeysDailyWD.findIndex(
                          (inactiveKey) => inactiveKey == o.dataKey
                        ),
                        1
                      );
                      setInactiveKeyDailyWD([...temp]);
                    }
                  }}
                />

                <Bar
                  type="monotone"
                  dataKey={"wd_zt"}
                  name="Control Zone"
                  fill={"#AA6666"}
                  unit={"mm"}
                  hide={
                    inactiveKeysDailyWD.find(
                      (inactiveKey) => inactiveKey == "wd_zt"
                    )
                      ? true
                      : false
                  }
                />

                <Bar
                  type="monotone"
                  dataKey={"wd_under_panel"}
                  name="Under Panels"
                  fill={"#66AA66"}
                  unit={"mm"}
                  hide={
                    inactiveKeysDailyWD.find(
                      (inactiveKey) => inactiveKey == "wd_under_panel"
                    )
                      ? true
                      : false
                  }
                />
                <Bar
                  type="monotone"
                  dataKey={"wd_between_panel"}
                  name="Between Panels"
                  fill={"#6666AA"}
                  unit={"mm"}
                  hide={
                    inactiveKeysDailyWD.find(
                      (inactiveKey) => inactiveKey == "wd_between_panel"
                    )
                      ? true
                      : false
                  }
                />
                <Bar
                  type="monotone"
                  dataKey={"wd_agri_pv"}
                  name="AgriPV Zone"
                  fill={"#444444"}
                  unit={"mm"}
                  hide={
                    inactiveKeysDailyWD.find(
                      (inactiveKey) => inactiveKey == "wd_agri_pv"
                    )
                      ? true
                      : false
                  }
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div>
        <div className={styled.downloadContainer}>
          <div className={styled.chartTitle}>
            <FormattedMessage
              {...WaterBalanceResultBodyMessages.waterDeficitTableTitle}
            />
          </div>
          <TooltipMUI
            title={intl.formatMessage(
              SimulationResultBodyMessages.hintChartToPng
            )}
          >
            <div
              className={styled.chartToPngButton}
              onClick={chartToPng("Table Water Deficit")}
            >
              <Icon
                name={ICON_NAMES_ENUM.download_image}
                className={styled.file__icon}
              />
            </div>
          </TooltipMUI>
          <TooltipMUI
            title={intl.formatMessage(
              WaterBalanceResultBodyMessages.hintChartToCsv
            )}
          >
            <div
              className={styled.chartToPngButton}
              onClick={chartToCsv(
                tableValues,
                `${result.simulation.name} Water Deficit Table`
              )}
            >
              <Icon
                name={ICON_NAMES_ENUM.download_file}
                className={styled.file__icon}
              />
            </div>
          </TooltipMUI>
        </div>
        <div id="Table Water Deficit">
          <table className={styled.dataTable}>
            <thead>
              <tr>
                <th rowSpan={2}>
                  <FormattedMessage {...CropCreateMessages.stageName} />
                </th>
                <th colSpan={4}>
                  <FormattedMessage
                    {...WaterBalanceResultMenuMessages.waterDeficitPerStage}
                    values={{ unite: "mm" }}
                  />
                </th>
              </tr>
              <tr className={styled.specialCaseTable}>
                <th>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.controlZoneSubTitle}
                    />
                  </div>
                </th>
                <th>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.underPanelsSubTitle}
                    />
                  </div>
                </th>
                <th>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.betweenPanelsZoneSubTitle}
                    />
                  </div>
                </th>
                <th>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.agriPVZoneSubTitle}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableValues != null &&
                tableValues.map((item: any) => (
                  <tr key={`tableIndex${item.name}`}>
                    <td>{item.period.name}</td>
                    <td>{item.wd_zt}</td>
                    <td>{item.wd_under_panel}</td>
                    <td>{item.wd_between_panel}</td>
                    <td>{item.wd_agri_pv}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default WaterDeficitContent;
