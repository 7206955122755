import React, { useEffect, useState } from "react";
import Button from "components/common/button/Button";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { ReactComponent as Save } from "assets/icons/save.svg";
import { ReactComponent as Long } from "assets/icons/long.svg";
import { ReactComponent as Lat } from "assets/icons/lat.svg";
import styled from "./styled.module.scss";
import { FormikValues, useFormik } from "formik";
import Input from "components/common/input/Input";
import ModalContainer from "components/ModalContainer/ModalContainer";
import DirectorySchema from "components/DirectoryModal/DirectorySchema";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import DirectoryModalMessages from "components/DirectoryModal/DirectoryModalMessages";
import { useDispatch } from "react-redux";
// import { createDirectory, updateDirectory } from "store/projects/actions";
import { useParams } from "react-router-dom";
import tzLookup from 'tz-lookup';
import moment from 'moment-timezone';
import { createDirectory, editDirectory } from "store/directorys/actions";

interface IDirectoryModal {
  show: boolean;
  onHide: () => void;
  initialValues?: IDirectoryForm;
  editId?: string | number;
}

interface IDirectoryForm {
  name: string;
}

const defaultFormValue: IDirectoryForm = {
  name: "",
};

const DirectoryModal = ({
  show,
  onHide,
  editId,
  initialValues = defaultFormValue,
}: IDirectoryModal) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { client_id } = useParams();

  const onSubmit = (values: FormikValues) => {
    if (editId) {
      dispatch(
        editDirectory({ clientId: client_id, id: editId, ...values })
      );
    } else {
      dispatch(createDirectory({ clientId: client_id, ...values }));
    }
    onHide();
  };

  const formik = useFormik<IDirectoryForm>({
    validationSchema: DirectorySchema(intl),
    onSubmit,
    initialValues,
  });

  useEffect(() => {
    formik.setValues(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (!show) {
      formik.resetForm();
    }
  }, [show]);

  return (
    <ModalContainer
      show={show}
      onHide={onHide}
      dialogClassName={styled.dialog}
      title={
        editId
          ? intl.formatMessage(DirectoryModalMessages.editDirectory)
          : intl.formatMessage(DirectoryModalMessages.createNew)
      }
    >
      <form className={styled.form} onSubmit={formik.handleSubmit}>
        <Input
          name="name"
          label={intl.formatMessage(DirectoryModalMessages.nameLabel)}
          placeholder={intl.formatMessage(DirectoryModalMessages.namePlaceholder)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          errorMessage={(formik.touched.name && formik.errors.name) as string}
        />

        <div className={styled.row}>
          <Button iconBefore={editId ? <Save /> : <Plus />} type="submit">
            {editId ? (
              <FormattedMessage {...commonMessages.save} />
            ) : (
              <FormattedMessage {...commonMessages.create} />
            )}
          </Button>
          <Button variant="text" onClick={onHide}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default DirectoryModal;
