import Select from "components/common/select/Select";
import AvailableWaterStockContent from "./AvailableWaterStockContent";
import ETPContent from "./ETPContent";
import WaterConsumptionContent from "./WaterConsumptionContent";
import WaterDeficitContent from "./WaterDeficitContent";
import styled from "./styled.module.scss";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const WaterbalanceContent = ({ result }: any) => {
  const chartTypes = [
    {
      label: "Water Balance",
      value: "WATER_BALANCE",
    },
    // {
    //   label: "Water Consumption",
    //   value: "WATER_CONSUMPTION",
    // },
    {
      label: "Water Deficit",
      value: "WATER_DEFICIT",
    },
    {
      label: "ETM",
      value: "ETM",
    },
  ];
  const chartUnites = [
    {
      label: "%",
      value: "FRACTION",
    },
    {
      label: "mm",
      value: "MM",
    },
  ];
  const chartUnitesWaterDeficit = [
    {
      label: "mm",
      value: "MM",
    },
  ];
  const ETMTableTypeOptions = [
    { label: "Per Month", value: "MONTH" },
    { label: "Per Stage", value: "STAGE" },
  ];
  const [selectedChartType, setSelectedChartType] = useState<
    "WATER_BALANCE" | "WATER_DEFICIT" | "ETM"
  >("WATER_BALANCE");
  const [selectedChartUnite, setSelectedChartUnite] = useState<
    "FRACTION" | "MM"
  >("FRACTION");
  const [selectedETMTableTypeOptions, setSelectedETMTableTypeOptions] =
    useState<"STAGE" | "MONTH">("MONTH");

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);
    const chart_type = searchParams.get('chart_type')
    const unit = searchParams.get('unit')
    if (chartTypes.find((item) => item.value == chart_type)) {
      setSelectedChartType(chart_type as any)
      if (chart_type == "WATER_DEFICIT") setSelectedChartUnite("MM");
    }
    else
      newParams.set("chart_type", selectedChartType)

    if (chartUnites.find((item) => item.value == unit)) {
      if (chart_type == "WATER_DEFICIT") {
        setSelectedChartUnite("MM")
        newParams.set("unit", "MM")
      }
      else {
        setSelectedChartUnite(unit as any)
      }
    }
    else
      newParams.set("unit", selectedChartUnite)

    if (chart_type == "ETM") {
      const ETM_table_type = searchParams.get('ETM_table_type')

      if (ETMTableTypeOptions.find((item) => item.value == ETM_table_type)) {
        setSelectedETMTableTypeOptions(ETM_table_type as any)
      }
      else
        newParams.set("ETM_table_type", selectedETMTableTypeOptions)
    }
    setSearchParams(newParams)
  }, [])

  const selectChartType = (value: any) => {
    setSelectedChartType(value);
    const newParams = new URLSearchParams(searchParams);
    newParams.set("chart_type", value)
    if (value == "WATER_DEFICIT") {
      setSelectedChartUnite("MM")
      newParams.set("unit", "MM")
    }
    setSearchParams(newParams)
  }
  const selectChartUnite = (value: any) => {
    setSelectedChartUnite(value);
    const newParams = new URLSearchParams(searchParams);
    newParams.set("unit", value)
    setSearchParams(newParams)
  }
  const selectETMTableTypeOptions = (value: any) => {
    setSelectedETMTableTypeOptions(value);
    const newParams = new URLSearchParams(searchParams);
    newParams.set("ETM_table_type", value)
    setSearchParams(newParams)
  }

  const Content = () => {
    if (selectedChartType == "WATER_BALANCE")
      return (
        <AvailableWaterStockContent
          result={result}
          unite={selectedChartUnite}
        />
      );
    // else if (selectedChartType == "WATER_CONSUMPTION")
    //   return (
    //     <WaterConsumptionContent result={result} unite={selectedChartUnite} />
    //   );
    else if (selectedChartType == "WATER_DEFICIT")
      return <WaterDeficitContent result={result} unite={selectedChartUnite} />;
    else if (selectedChartType == "ETM")
      return (
        <ETPContent
          result={result}
          unite={selectedChartUnite}
          period={selectedETMTableTypeOptions}
        />
      );
    else return <></>;
  };

  return (
    <div className={styled.list}>
      <div className={styled.chartContainer}>
        <div className={styled.optionContainer}>
          <div className={styled.doubleUniteSelector}>
            <div
              className={styled.doubleUniteSelectorItem}
              style={{
                width: "300px",
              }}
            >
              <Select
                label="Chart Type"
                labelPosition="left"
                options={chartTypes}
                value={chartTypes.find(
                  (item) => item.value == selectedChartType
                )}
                onChange={(e: any) => {
                  selectChartType(e.value);
                }}
                isSearchable={false}
                menuHeight={400}
              />
            </div>
            {selectedChartType !== "WATER_DEFICIT" && (
              <div className={styled.doubleUniteSelectorItem}>
                <Select
                  label="Chart Unit"
                  labelPosition="left"
                  options={chartUnites}
                  value={chartUnites.find(
                    (item) => item.value == selectedChartUnite
                  )}
                  onChange={(e: any) => {
                    selectChartUnite(e.value);
                  }}
                  isSearchable={false}
                  menuHeight={400}
                />
              </div>
            )}
            {selectedChartType === "WATER_DEFICIT" && (
              <div className={styled.doubleUniteSelectorItem}>
                <Select
                  label="Chart Unit"
                  labelPosition="left"
                  options={chartUnitesWaterDeficit}
                  value={chartUnitesWaterDeficit.find(
                    (item) => item.value == selectedChartUnite
                  )}
                  onChange={(e: any) => {
                    selectChartUnite(e.value);
                  }}
                  isSearchable={false}
                  menuHeight={400}
                />
              </div>
            )}
            {selectedChartType === "ETM" && (
              <div className={styled.doubleUniteSelectorItem}>
                <Select
                  label="Period"
                  labelPosition="left"
                  options={ETMTableTypeOptions}
                  value={ETMTableTypeOptions.find(
                    (item) => item.value == selectedETMTableTypeOptions
                  )}
                  onChange={(e: any) => {
                    selectETMTableTypeOptions(e.value);
                  }}
                  isSearchable={false}
                  menuHeight={400}
                />
              </div>
            )}
          </div>
        </div>
        <Content />
      </div>
      {/* <div className={styled.row}>
			<div style={{ width: "calc(50% - 1rem)" }}>
				<AvailableWaterStockContent result={result} />
			</div>
			<div className={styled.verticalLimiter} />
			<div style={{ width: "calc(50% - 1rem)" }}>
				<WaterConsumptionContent result={result} />
			</div>
		</div>
		<div className={styled.limiter} />
		<div className={styled.row}>
			<div style={{ width: "calc(50% - 1rem)" }}>
				<WaterDeficitContent result={result} />
			</div>
			<div className={styled.verticalLimiter} />
			<div style={{ width: "calc(50% - 1rem)" }}>
				<ETPContent result={result} />
			</div>
		</div> */}
    </div>
  );
};

export default WaterbalanceContent;
