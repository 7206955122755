import React from "react";
import styled from "components/SimulationDropItem/styled.module.scss";
import Line from "components/SimulationDropItem/Line";

const DatasetRenderList = ({ selected }: { selected: any }) => {
  return (
    <ul className={styled.ul}>
      <br />
      <Line
        title="Dataset type"
        value={
          selected.dataset_type === "universal"
            ? "Climatic"
            : selected.dataset_type
        }
      />
      <Line title="Sampling rate" value={selected.frequency} />
      {selected.origin ? <Line title="Origin" value={selected.origin} /> : null}
      {selected.year ? <Line title="Year" value={selected.year} /> : null}
    </ul>
  );
};

export default DatasetRenderList;
