const chartToCsv = (chartData: any, title: string) => () => {
  const generateCSV = (data: any) => {
    let csvContent = "";

    if (data.length === 0) {
      return csvContent;
    }
    const keys = Object.keys(data[0]);
    csvContent += keys.join(",") + "\n";
    data.forEach((item: any) => {
      const row = keys.map((key) => {
        if (typeof item[key] == "object")
          return item[key].name || item[key].id || "Error";
        else return item[key];
      });
      csvContent += row.join(",") + "\n";
    });
    return csvContent;
  };

  const saveFile = (file: any) => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(file);
    link.download = file.name;
    link.click();
  };

  const csvData = generateCSV(chartData);
  const blob = new Blob(["\ufeff", csvData], {
    type: "text/csv;charset=utf-8",
  });
  const url = URL.createObjectURL(blob);

  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const file = new File([blob], `${title}.csv`, {
        type: "text/csv;charset=utf-8",
      });
      URL.revokeObjectURL(url);
      saveFile(file);
    });
};

export default chartToCsv;
