import { object, string, array, number } from "yup";
import { IntlShape } from "react-intl";
import validationMessages from "components/common/ValidationMessages";
import steeringAlgorithmMessages from "pages/SteeringAlgorithmsCreate/SteeringAlgorithmsCreateMessages";
import { IRowType } from "./SteeringAlgorithmsCreateTypes";

const SteeringAlgorithmSchema = (intl: IntlShape) =>
  object().shape({
    name: string()
      .max(50, intl.formatMessage(validationMessages.maxLength, { number: 50 }))
      .required(intl.formatMessage(validationMessages.required)),
    crop_id: number().moreThan(
      0,
      intl.formatMessage(validationMessages.required)
    ),
    row: array()
      .min(1, intl.formatMessage(validationMessages.required))
      .test(
        "unique-type-check",
        intl.formatMessage(steeringAlgorithmMessages.typeMismatch),
        (rows) => {
          if (!rows || rows.length === 0) return true;

          const uniqueTypes = new Set(rows.map((row) => row.type));

          return uniqueTypes.size === 1;
        }
      )
      .test(
        "unique-period-check",
        intl.formatMessage(steeringAlgorithmMessages.periodMismatch),
        (rows) => {
          if (!rows || rows.length === 0) return true;

          const periodMap = new Map();

          for (const row of rows) {
            if (row.type !== IRowType.time_slot) {
              if (periodMap.has(row.period_id)) {
                return false;
              }
              periodMap.set(row.period_id, true);
            }
          }
          return true;
        }
      ),
  });

export default SteeringAlgorithmSchema;
