import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import styled from './styled.module.scss';
import { ReactComponent as Warning } from "../../assets/icons/warning.svg";
import { ReactComponent as Information } from "../../assets/icons/information.svg";
import { getAlert } from 'store/alert/api';

const ModalUserAlert = ({ show, onClose }: any) => {

	const [alertData, setAlertData] = useState<any>(null)

	useEffect(() => {
		if (show) {
			getAlert().then((res) => {
				setAlertData(res.data)
			}).catch(() => {
				setAlertData(null);
			});
		}
	}, [show]);

	if (!show) return null;

	return (
		<div>
			{alertData ? (
				<div className={clsx(styled.modalUserAlert, { [styled.show]: show })}>
					<div className={styled.modalContent}>
						<div className={styled.alertContainer}>
							<div className={clsx(styled.preview, styled[alertData.type])}>
								<div className={styled.titleContainer}>
									<div className={styled.bigIcon}>
										{alertData.type === 'warning' ? <Warning /> : <Information />}
									</div>
									{alertData.type}
								</div>
								<div className={styled.text}>
									<textarea value={alertData.text} />

								</div>
							</div>
						</div>
						<div className={styled.modalFooter}>
							<button onClick={onClose}>Close</button>
						</div>
					</div>
				</div>
			) : (
				<div className={clsx(styled.noAlertModal, { [styled.show]: show })}>
					<div className={styled.modalContent}>
						<div className={styled.alertContainer}>
							<div className={styled.noAlertMessage}>
								<p>No alert available at the moment.</p>
							</div>
						</div>
						<div className={styled.modalFooter}>
							<button onClick={onClose}>Close</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ModalUserAlert;
