import { defineMessages } from "react-intl";

export default defineMessages({
  waterBalancePage: {
    id: "BatchesWaterBalanceResultMessages.waterBalancePage",
    defaultMessage: "Water Balance Page",
  },
  status: {
    id: "BatchesWaterBalanceResultMessages.status",
    defaultMessage: "Status",
  },
});
