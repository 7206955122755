import chartToCsv from "utils/chartToCsv";
import { useEffect, useMemo, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import styled from "./styled.module.scss";
import { ReactComponent as Structures } from "assets/icons/structures.svg";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import StructuresRenderList from "components/SimulationDropItem/StructuresRenderList";
import { ReactComponent as Crops } from "assets/icons/crops.svg";
import CropsRenderList from "components/SimulationDropItem/CropsRenderList";
import { ReactComponent as DataTemp } from "assets/icons/data-temp.svg";
import simulationDropItemMessages from "components/SimulationDropItem/SimulationDropItemMessages";
import DatasetRenderList from "components/SimulationDropItem/DatasetRenderList";
import { ReactComponent as DataElec } from "assets/icons/data-elec.svg";
import WaterBalanceResultBodyMessages from "./WaterBalanceResultBodyMessages";
import { default as LineSimulation } from "components/SimulationDropItem/Line";
import Spinner from "react-bootstrap/Spinner";
import Select from "components/common/select/Select";
import { SpanError } from "components/common/span-error/SpanError";
import { StatusesEnum } from "components/StatusLabel/StatusLabel";
import Button from "components/common/button/Button";
import SoilInformationsRenderList from "components/WaterBalanceDropItem/SoilInformationsRenderList";
import clsx from "clsx";
import { ReactComponent as Arrow } from "assets/icons/arrow.svg";
import { Tooltip } from "@mui/material";
import html2canvas from "html2canvas";
import { errorNotifications } from "utils/errorNotifications";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import SteeringAlgorithmsRenderList from "components/SimulationDropItem/SteeringAlgorithmsRenderList";
import JSZip from "jszip";
import {
  getDatasetDailyChart,
  getDatasetMonthlyChart,
} from "store/datasets/api";
import { useParams } from "react-router-dom";
import moment from "moment";
import BatchesResultMessages from "pages/BatchesResult/BatchesResultMessages";
import CropCreateMessages from "pages/CropsCreate/CropCreateMessages";
import AddModalPeriodCropsTableMessages from "components/AddModalPeriodCropsTable/AddModalPeriodCropsTableMessages";
import CommonMessages from "components/common/CommonMessages";
import SimulationCreateMessages from "pages/SimulationCreate/SimulationCreateMessages";
import SimulationResultBodyMessages from "components/SimulationResultBody/SimulationResultBodyMessages";
import WaterBalanceResultMenu from "components/WaterBalanceResultMenu/WaterBalanceResultMenu";
import chartToPng from "utils/chartToPng";
import { getRecapTable } from "store/analisys/api";
import WaterBalanceResultMenuMessages from "components/WaterBalanceResultMenu/WaterBalanceResultMenuMessages";
import { default as TooltipMUI } from "@mui/material/Tooltip";

interface IWaterBalanceResultBody {
  water_balance_id: string | number;
  result: any;
  selectedPeriod: IPeriod;
  image: string;
  onSelect: (option: any) => void;
  onCancelWaterBalance?: () => void;
}

interface ISelectOption {
  value: string;
  label: string;
}

const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export type IPeriod = ISelectOption | null;

const RUUnites = ["Fill (mm)", "Fill (%)"];

function getEquidistantDates(crop: any, n: number) {
  const startDateList = crop.periods.map(
    (period: any) => new Date(period.start_date)
  );
  const endDateList = crop.periods.map(
    (period: any) => new Date(period.end_date)
  );
  const startDate = new Date(Math.min(...startDateList));
  const endDate = new Date(Math.max(...endDateList));
  const start = new Date(startDate);
  const end = new Date(endDate);
  const totalDiff = end.getTime() - start.getTime();
  const interval = totalDiff / (n + 1);
  const dates = [];
  for (let i = 1; i <= n; i++) {
    const date = new Date(start.getTime() + interval * i);
    dates.push(moment(date).format("YYYY-MM-DD"));
  }

  return dates;
}

const WaterBalanceResultBody = ({
  water_balance_id,
  result,
  selectedPeriod,
  image,
  onSelect,
  onCancelWaterBalance,
}: IWaterBalanceResultBody) => {
  const intl = useIntl();

  const [isCanceling, setIsCanceling] = useState(false);

  const isPrepare = result?.status === StatusesEnum.PREPARE;
  const isRunningMain = result?.status === StatusesEnum.RUNNING_MAIN;
  const isRunningPeriods = result?.status === StatusesEnum.RUNNING_PERIODS;
  const isProgressSimulation = isPrepare || isRunningMain || isRunningPeriods;
  const isSuccessSimulation = result?.status === StatusesEnum.COMPLETED;
  const isFailedSimulation = result?.status === StatusesEnum.FAILED;

  const currentProgress = useMemo(() => {
    const sumPeriodsPath = result?.result_data?.reduce(
      (a: any, { current, length, status }: any) => {
        if (status === StatusesEnum.COMPLETED) {
          a += length;
        } else {
          a += current;
        }

        return a;
      },
      0
    );
    const mainPath =
      isRunningMain || isPrepare ? result.current : result.length;

    return (mainPath + sumPeriodsPath) / (result.total_length / 100) || 0;
  }, [result]);

  const calculatePeriod = (period: any) => {
    if (period.status === "enqueued") return "Waiting...";
    if (period.status === "completed") return "Completed";
    return `${(period.current / (period.length / 100)).toFixed(1)} %`;
  };

  const onCancel = () => {
    if (onCancelWaterBalance) onCancelWaterBalance();
    setIsCanceling(true);
  };

  const { client_id, project_id, id, simulation_water_balance_id } = useParams();

  // const [tableValues, setTableValues] = useState([])

  const [tableValues, setTableValues] = useState<any>(null);

  useEffect(() => {
    if (result?.status === StatusesEnum.COMPLETED)
      getRecapTable({
        clientId: client_id,
        projectId: project_id,
        id: id || simulation_water_balance_id,
      }).then((res) => setTableValues(res.data));
  }, [client_id, project_id, water_balance_id, result]);

  const downloadTableData = () => {
    if (tableValues)
      return {
        "Farming zone fraction (%)": tableValues.farming_zone_fraction,
        "Ground Coverage Ratio (%)": tableValues.gcr,
        "Installed Capacity (kWc/ha)": tableValues.installed_capacity,
        "Annual Irradiance (%) - Under Panel":
          tableValues.irradiance.under_panel,
        "Annual Irradiance (%) - Between Panels":
          tableValues.irradiance.between_panel,
        "Annual Irradiance (%) - AgriPv": tableValues.irradiance.agri_pv,
        "Annual ETP (%) - Under Panel": tableValues.etp.under_panel,
        "Annual ETP (%) - Between Panels": tableValues.etp.between_panel,
        "Annual ETP (%) - AgriPv": tableValues.etp.agri_pv,
        "Annual Production (kWh/kWc) - Sun Tracking":
          tableValues.production.production_sun_tracking,
        "Annual Production (kWh/kWc) - AgriPV":
          tableValues.production.production_agri_pv,
      };
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <div className={styled.simuEssenceContainer}>
          <div
            className={clsx(styled.simulationResultInfoContainer, {
              [styled.onProgress]: isProgressSimulation,
            })}
          >
            <div
              className={clsx(styled.simulationResultImage, {
                [styled.onProgress]: isProgressSimulation,
              })}
            >
              <h3 className={styled.statusTitle}>
                {isProgressSimulation ? (
                  <FormattedMessage
                    {...WaterBalanceResultBodyMessages.simulationInProgress}
                  />
                ) : (
                  <FormattedMessage
                    {...WaterBalanceResultBodyMessages.simulationResults}
                  />
                )}
              </h3>
              {isSuccessSimulation && (
                <div className={styled.waitContainer}>
                  <Tooltip
                    title={intl.formatMessage(
                      WaterBalanceResultBodyMessages.hintDownloadImg
                    )}
                  >
                    <a
                      href={image}
                      download={"imageName"}
                      className={styled.imgDownload}
                    >
                      <Icon
                        name={ICON_NAMES_ENUM.download_image}
                        className={styled.file__icon}
                      />
                    </a>
                  </Tooltip>
                  <img
                    className={styled.resultImage}
                    src={image}
                    alt="Simulation result image"
                  />
                </div>
              )}
              {isProgressSimulation && (
                <>
                  <div className={styled.progressBarContainer}>
                    <div className={styled.waitItem}>
                      <Spinner animation="border" className={styled.spinner} />
                      <p className={styled.inProgress}>
                        <FormattedMessage
                          {...WaterBalanceResultBodyMessages.inProgress}
                          values={{
                            value: isRunningPeriods ? 2 : 1,
                          }}
                        />
                      </p>

                      {!isNaN(currentProgress) && (
                        <>
                          <ProgressBar
                            striped
                            now={currentProgress}
                            className={styled.progressBar}
                          />
                          <h5 className={styled.progressBarLabel}>
                            {currentProgress.toFixed(0)} %
                          </h5>

                          <ul className={styled.list}>
                            {isRunningPeriods ? (
                              result.result_data.map((periodResult: any) => (
                                <LineSimulation
                                  title={periodResult.period.name}
                                  value={calculatePeriod(periodResult)}
                                />
                              ))
                            ) : (
                              <LineSimulation
                                title="Main"
                                value={`${(
                                  result.current /
                                  (result.length / 100)
                                ).toFixed(1)} %`}
                              />
                            )}
                          </ul>
                        </>
                      )}
                    </div>
                  </div>

                  {!!onCancelWaterBalance && (
                    <Button
                      variant="outlined"
                      className={styled.cancelBtn}
                      onClick={onCancel}
                      disabled={isCanceling}
                      isLoading={isCanceling}
                    >
                      <FormattedMessage {...commonMessages.cancel} />
                    </Button>
                  )}
                </>
              )}

              {isFailedSimulation && (
                <SpanError errorMessage={result?.exception ?? ""} />
              )}
            </div>
            {tableValues != null && (
              <div className={styled.simulationResultInfo} id="resultTable">
                <div className={styled.downloadContainer}>
                  <TooltipMUI
                    title={intl.formatMessage(
                      WaterBalanceResultBodyMessages.hintTablesToPng
                    )}
                  >
                    <div
                      className={styled.chartToPngButton}
                      onClick={chartToPng("resultTable")}
                    >
                      <Icon
                        name={ICON_NAMES_ENUM.download_image}
                        className={styled.file__icon}
                      />
                    </div>
                  </TooltipMUI>
                  <TooltipMUI
                    title={intl.formatMessage(
                      WaterBalanceResultBodyMessages.hintTablesToCsv
                    )}
                  >
                    <div
                      className={styled.chartToPngButton}
                      onClick={() => {
                        chartToCsv(
                          [downloadTableData()],
                          `${result.simulation.name} result table`
                        )();
                      }}
                    >
                      <Icon
                        name={ICON_NAMES_ENUM.download_file}
                        className={styled.file__icon}
                      />
                    </div>
                  </TooltipMUI>
                </div>
                <table className={styled.dataTable}>
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage
                          {...BatchesResultMessages.farmingZoneFraction}
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          {...BatchesResultMessages.groundCoverageRatio}
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          {...BatchesResultMessages.installedCapacity}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{tableValues.farming_zone_fraction}</td>
                      <td>{tableValues.gcr}</td>
                      <td>{tableValues.installed_capacity}</td>
                    </tr>
                  </tbody>
                </table>

                <table className={styled.dataTable}>
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        <FormattedMessage
                          {...WaterBalanceResultMenuMessages.underPanelsSubTitle}
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          {...WaterBalanceResultMenuMessages.betweenPanelsZoneSubTitle}
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          {...WaterBalanceResultMenuMessages.agriPVZoneSubTitle}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <FormattedMessage
                          {...WaterBalanceResultBodyMessages.annualIrr}
                        />
                      </td>
                      <td>{tableValues.irradiance.under_panel}</td>
                      <td>{tableValues.irradiance.between_panel}</td>
                      <td>{tableValues.irradiance.agri_pv}</td>
                    </tr>
                    <tr>
                      <td>
                        <FormattedMessage
                          {...WaterBalanceResultBodyMessages.annualETP}
                        />
                      </td>
                      <td>{tableValues.etp.under_panel}</td>
                      <td>{tableValues.etp.between_panel}</td>
                      <td>{tableValues.etp.agri_pv}</td>
                    </tr>
                  </tbody>
                </table>

                <table className={styled.dataTable}>
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        <FormattedMessage
                          {...WaterBalanceResultBodyMessages.annualProd}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <FormattedMessage
                          {...WaterBalanceResultBodyMessages.sunTracking}
                        />
                      </td>

                      <td>{tableValues.production.production_sun_tracking}</td>
                    </tr>
                    {result.simulation.steering_algorithm_for_water_balance ? (
                      <tr>
                        <td>
                          <FormattedMessage
                            {...WaterBalanceResultMenuMessages.agriPVZoneSubTitle}
                          />
                        </td>
                        <td>{tableValues.production.production_agri_pv}</td>
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>

      {isSuccessSimulation && <WaterBalanceResultMenu result={result} />}
    </>
  );
};

export default WaterBalanceResultBody;
