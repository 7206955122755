import { useOptionData } from "hooks/useOptionData";
import { useEffect, useMemo, useState } from "react";
import styled from "./styled.module.scss";
import { ReactComponent as Structures } from "assets/icons/structures.svg";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import StructureTemplateModalMessages from "./StructureTemplateModalMessages";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import CommonMessages from "components/common/CommonMessages";
import { ICropType } from "pages/CropsCreate/CropsCreateTypes";
import { IStructure } from "pages/StructuresCreate/StructuresCreate";
import Input from "components/common/input/Input";
import StructuresCreateMessages from "pages/StructuresCreate/StructuresCreateMessages";
import InputRange from "components/common/inputRange/InputRange";
import DottedLine from "components/DottedLine/DottedLine";
import Label from "components/common/label/Label";
import Switch from "components/common/switch/Switch";
import CheckBox from "components/common/checkBox/CheckBox";
import Button from "components/common/button/Button";
import { useSelector } from "react-redux";
import { getProjectById } from "store/projects/selectors";
import { useParams } from "react-router-dom";
import { Structure_3D } from "components/Structure_3D/Structure_3D";
import { SingleDatePicker } from "react-dates";
import { ReactComponent as Calendar } from "assets/icons/calendar.svg";
import Select from "components/common/select/Select";
import moment, { Moment } from "moment";

const monthOptions = moment.months().map((label, value) => ({ label, value }));

type IStructureTemplateModal = {
  isOpen: boolean;
  templates: IStructure[];
  onHideModal: () => void;
  onSelectStructureTemplate: (template: IStructure) => void;
  onBack: () => void;
};

const StructureTemplateModal = ({
  isOpen,
  templates,
  onHideModal,
  onSelectStructureTemplate,
  onBack,
}: IStructureTemplateModal) => {
  const [datePickerFocus, setDatePickerFocus] = useState(false);
  const { client_id, project_id } = useParams();
  const [templateSelected, setTemplateSelected] = useState<any | null>(null);
  const currentProject = useSelector(getProjectById(project_id));
  const [isDate, setIsDate] = useState(false);
  const [date, setDate] = useState<Moment | null>(null);
  const intl = useIntl();

  const [defaultPositionCanvas, setDefaultPositionCanvas] = useState(false);

  useEffect(() => {
    if (!isOpen) setTemplateSelected(null);
  }, [isOpen]);

  useEffect(() => {
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key == "Escape") {
        onHideModal();
      }
    };
    if (isOpen) window.addEventListener("keyup", onKeyUp);
    return () => window.removeEventListener("keyup", onKeyUp);
  }, [isOpen]);

  if (!isOpen) return <></>;
  return (
    <div className={styled.absolute}>
      <div className={styled.modalBackground}>
        <div className={styled.modalContainer}>
          <div className={styled.leftSide}>
            <div className={styled.templateSelectorLabel}>
              <FormattedMessage
                {...StructureTemplateModalMessages.selectTemplate}
              />
            </div>
            <div className={styled.littleLimiter} />
            <div className={styled.templateSelector}>
              {templates.map((structure) => (
                <div
                  key={structure.name}
                  className={clsx(styled.templateSelectorItem, {
                    [styled.selected]:
                      templateSelected &&
                      templateSelected.name == structure.name,
                  })}
                  onClick={() => {
                    var obj: any = {
                      ...structure,
                      panel_width:
                        structure.structure_type == "fixed"
                          ? structure.panel_y
                          : structure.panel_x,
                      panel_length:
                        structure.structure_type == "fixed"
                          ? structure.panel_x
                          : structure.panel_y,
                      panels_gap_x:
                        structure.panels_gap_x -
                        (structure.structure_type == "fixed"
                          ? structure.panel_x
                          : 0),
                      panels_gap_y:
                        structure.panels_gap_y -
                        (structure.structure_type == "tracker"
                          ? structure.panel_y
                          : 0),
                    };

                    const angle =
                      obj.structure_type == "fixed"
                        ? obj.static_angle
                        : obj.tracking_max_angle;
                    const panel_size = obj.panel_width / 2;
                    const val1 =
                      Math.round(
                        (obj.panel_height +
                          panel_size * Math.sin((angle * Math.PI) / 180)) *
                          100
                      ) / 100;
                    const val2 =
                      Math.round(
                        (obj.panel_height -
                          panel_size * Math.sin((angle * Math.PI) / 180)) *
                          100
                      ) / 100;
                    const lower = val1 < val2 ? val1 : val2;
                    const upper = val1 > val2 ? val1 : val2;
                    obj = {
                      ...obj,
                      panel_lower: lower,
                      panel_upper: upper,
                      points: [],
                    };
                    setTemplateSelected(obj);
                  }}
                >
                  <Structures />
                  {structure.name}
                </div>
              ))}
            </div>
          </div>
          <div className={styled.rightSide}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <input
                className={styled.templateLabel}
                disabled
                value={templateSelected ? templateSelected.name : ""}
                placeholder={intl.formatMessage(
                  StructureTemplateModalMessages.selectTemplate
                )}
              />
              <div style={{ display: "flex", gap: "1rem" }}>
                <div className={styled.cancelButton} onClick={onBack}>
                  <FormattedMessage {...CommonMessages.cancel} />{" "}
                </div>

                <div
                  className={clsx(styled.selectButton, {
                    [styled.disabled]: templateSelected == null,
                  })}
                  onClick={() => onSelectStructureTemplate(templateSelected!)}
                >
                  <FormattedMessage
                    {...StructureTemplateModalMessages.selectTemplate}
                  />{" "}
                </div>
              </div>
            </div>

            <div className={styled.littleLimiter} />

            {templateSelected && (
              <div className={styled.columnRender}>
                <div className={styled.titleRow}>
                  <h2 className={styled.sectionTitle}>
                    <FormattedMessage
                      {...StructuresCreateMessages.visualDisplay}
                    />
                  </h2>

                  <Button
                    onClick={() => setDefaultPositionCanvas(true)}
                    variant="text"
                    className={styled.drawButton}
                  >
                    <FormattedMessage
                      {...StructuresCreateMessages.defaultPosition}
                    />
                  </Button>
                </div>

                <div className={styled.renderContainer}>
                  {currentProject && (
                    <Structure_3D
                      panel_height={templateSelected.panel_height}
                      panel_opacity={templateSelected.panel_opacity}
                      panel_x={
                        templateSelected.structure_type == "fixed"
                          ? templateSelected.panel_length
                          : templateSelected.panel_width
                      }
                      panel_y={
                        templateSelected.structure_type == "fixed"
                          ? templateSelected.panel_width
                          : templateSelected.panel_length
                      }
                      initial_offset_x={templateSelected.initial_offset_x}
                      initial_offset_y={templateSelected.initial_offset_y}
                      panels_number_x={templateSelected.panels_number_x}
                      panels_number_y={templateSelected.panels_number_y}
                      panels_gap_x={
                        templateSelected.panels_gap_x +
                        (templateSelected.structure_type == "fixed"
                          ? templateSelected.panel_length
                          : 0)
                      }
                      panels_gap_y={
                        templateSelected.panels_gap_y +
                        (templateSelected.structure_type == "tracker"
                          ? templateSelected.panel_length
                          : 0)
                      }
                      field_size_x={
                        templateSelected.structure_type === "fixed"
                          ? (templateSelected.panels_gap_x +
                              templateSelected.panel_x) *
                            templateSelected.panels_number_x
                          : templateSelected.panels_gap_x *
                              templateSelected.panels_number_x -
                            (templateSelected.panels_gap_x -
                              templateSelected.panel_x)
                      }
                      field_size_y={
                        templateSelected.structure_type === "fixed"
                          ? templateSelected.panels_gap_y *
                              templateSelected.panels_number_y -
                            templateSelected.panels_gap_y +
                            templateSelected.panel_y
                          : (templateSelected.panels_gap_y +
                              templateSelected.panel_y) *
                            templateSelected.panels_number_y
                      }
                      azimuth={templateSelected.azimuth}
                      structure_type={templateSelected.structure_type}
                      static_angle={templateSelected.static_angle}
                      tracking_max_angle={templateSelected.tracking_max_angle}
                      backtracking={templateSelected.backtracking}
                      displacement_tracking={
                        templateSelected.displacement_tracking
                      }
                      solar_position={templateSelected.solar_position}
                      azimuth_solar={templateSelected.azimuth_solar}
                      zenith_solar={templateSelected.zenith_solar}
                      day={templateSelected.day}
                      hour={templateSelected.hour}
                      no_crop_zone={templateSelected.no_crop_zone}
                      lat={currentProject.lat}
                      long={currentProject.long}
                      points={templateSelected.points}
                      defaultPositionBool={defaultPositionCanvas}
                      setDefaultPositionBool={setDefaultPositionCanvas}
                    />
                  )}
                </div>

                <div className={clsx(styled.mt, styled.titleRow)}>
                  <h2 className={styled.sectionTitle}>
                    <FormattedMessage
                      {...StructuresCreateMessages.sunPosition}
                    />
                  </h2>

                  {/* <Switch
                    label1={intl.formatMessage(StructuresCreateMessages.fixed)}
                    label2={intl.formatMessage(StructuresCreateMessages.date)}
                    value={isDate}
                    onChange={onChangeSolarPosition}
                  /> */}
                </div>

                {!isDate ? (
                  <>
                    <InputRange
                      label={intl.formatMessage(
                        StructuresCreateMessages.azimuth
                      )}
                      values={[templateSelected.azimuth_solar]}
                      min={-90}
                      max={90}
                      step={1}
                      onChange={(value) => {
                        setTemplateSelected((prev: any) => ({
                          ...prev,
                          azimuth_solar: value[0],
                        }));
                      }}
                    />

                    <InputRange
                      label={intl.formatMessage(
                        StructuresCreateMessages.zenith
                      )}
                      values={[templateSelected.zenith_solar]}
                      min={-90}
                      max={90}
                      step={1}
                      onChange={(value) => {
                        setTemplateSelected((prev: any) => ({
                          ...prev,
                          zenith_solar: value[0],
                        }));
                      }}
                    />
                  </>
                ) : (
                  <>
                    <DottedLine
                      item1={
                        <Label htmlFor="day">
                          <FormattedMessage {...StructuresCreateMessages.day} />
                        </Label>
                      }
                      item2={
                        // <DatePicker
                        //   id="day"
                        //   date={date}
                        //   onChange={onChangeDate}
                        //   openDown={false}
                        // />
                        <div className={styled.datepicker}>
                          <SingleDatePicker
                            openDirection={"up"}
                            customInputIcon={<Calendar />}
                            renderMonthElement={({
                              month,
                              onMonthSelect,
                              onYearSelect,
                            }) => (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  gap: "1rem",
                                }}
                              >
                                <div style={{ flex: 1 }}>
                                  <Select
                                    menuHeight={200}
                                    options={monthOptions}
                                    value={monthOptions.find(
                                      ({ value }) => value === month.month()
                                    )}
                                    onChange={(v: any) =>
                                      onMonthSelect(month, v.value)
                                    }
                                  />
                                </div>
                                {/* <div style={{ flex: 1 }}>
                                <Select
                                  menuHeight={200}
                                  options={yearOptions}
                                  value={yearOptions.find(
                                    ({ value }) => value === month.year()
                                  )}
                                  onChange={(v: any) => onYearSelect(month, v.value)}
                                />
                              </div> */}
                              </div>
                            )}
                            navNext={<span />}
                            navPrev={<span />}
                            id="date"
                            renderWeekHeaderElement={() => <span />}
                            inputIconPosition="after"
                            focused={datePickerFocus}
                            date={date}
                            onFocusChange={({ focused }) =>
                              setDatePickerFocus(focused)
                            }
                            onDateChange={(d) =>
                              setTemplateSelected((prev: any) => ({
                                ...prev,
                                day: d?.format("yyyy-MM-DD") ?? null,
                              }))
                            }
                            numberOfMonths={1}
                            displayFormat="DD MMM"
                            // id={id}
                            placeholder={"Date"}
                            isOutsideRange={() => false}
                          />
                        </div>
                      }
                    />

                    <DottedLine
                      item1={
                        <Label htmlFor="hour">
                          <FormattedMessage
                            {...StructuresCreateMessages.hour}
                          />
                        </Label>
                      }
                      item2={
                        <Input
                          type="number"
                          integerNumber
                          value={templateSelected.hour}
                          onChange={(value) =>
                            setTemplateSelected((prev: any) => ({
                              ...prev,
                              hour: Number(value.currentTarget.value),
                            }))
                          }
                          step={1}
                        />
                      }
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StructureTemplateModal;
