import { defineMessages } from "react-intl";

export default defineMessages({
  selected: {
    id: "ParametersInterestMessages.selected",
    defaultMessage: "{value} Selected",
  },
  panel_height: {
    id: "ParametersInterestMessages.panel_height",
    defaultMessage: "Rotation Axis Height",
  },
  lower_table_tip_height: {
    id: "ParametersInterestMessages.lower_table_tip_height",
    defaultMessage: "Lower table tip height",
  },
  panel_opacity: {
    id: "ParametersInterestMessages.panel_opacity",
    defaultMessage: "Panel Opacity",
  },
  panel_size: {
    id: "ParametersInterestMessages.panel_size",
    defaultMessage: "Table Size",
  },
  panel_tilt: {
    id: "ParametersInterestMessages.panel_tilt",
    defaultMessage: "Panel tilt",
  },
  panel_max_tilt: {
    id: "ParametersInterestMessages.panel_max_tilt",
    defaultMessage: "Panel Max Tilt",
  },
  panel_width: {
    id: "ParametersInterestMessages.panel_width",
    defaultMessage: "Table Width",
  },
  panel_length: {
    id: "ParametersInterestMessages.panel_length",
    defaultMessage: "Table Length",
  },
  initial_offset: {
    id: "ParametersInterestMessages.initial_offset",
    defaultMessage: "Initial Offset",
  },
  panels_number: {
    id: "ParametersInterestMessages.panels_number",
    defaultMessage: "Number of tables",
  },
  panels_gap: {
    id: "ParametersInterestMessages.panels_gap",
    defaultMessage: "Space Between Panels",
  },
  gap_between_adjacent_tables: {
    id: "ParametersInterestMessages.gap_between_adjacent_tables",
    defaultMessage: "Gap Between Adjacent Tables",
  },
  pitch_between_tables: {
    id: "ParametersInterestMessages.pitch_between_tables",
    defaultMessage: "Pitch Between Tables",
  },
  field_size: {
    id: "ParametersInterestMessages.field_size",
    defaultMessage: "Field Size",
  },
  azimuth: {
    id: "ParametersInterestMessages.azimuth",
    defaultMessage: "Azimuth",
  },
  crop_id: {
    id: "ParametersInterestMessages.crop_id",
    defaultMessage: "Crop",
  },
  weather_dataset_id: {
    id: "ParametersInterestMessages.weather_dataset_id",
    defaultMessage: "Weather Dataset",
  },
  ETP_dataset_id: {
    id: "ParametersInterestMessages.ETP_dataset_id",
    defaultMessage: "ETP Dataset",
  },
  production_dataset_id: {
    id: "ParametersInterestMessages.production_dataset_id",
    defaultMessage: "Production Dataset",
  },
  universal_dataset_id: {
    id: "ParametersInterestMessages.universal_dataset_id",
    defaultMessage: "Climatic Dataset",
  },
  steering_algorithm_id: {
    id: "ParametersInterestMessages.steering_algorithm_id",
    defaultMessage: "Steering Algorithm",
  },
  soil_information_id: {
    id: "ParametersInterestMessages.soil_information_id",
    defaultMessage: "Soil Information",
  },
  search: {
    id: "ParametersInterestMessages.search",
    defaultMessage: "Search",
  },
});
