import { defineMessages } from "react-intl";

export default defineMessages({
  editDirectory: {
    id: "DirectoryModalMessages.editDirectory",
    defaultMessage: "Edit directory",
  },
  createNew: {
    id: "DirectoryModalMessages.createNew",
    defaultMessage: "Create a new directory",
  },
  nameLabel: {
    id: "DirectoryModalMessages.nameLabel",
    defaultMessage: "Directory name",
  },
  namePlaceholder: {
    id: "DirectoryModalMessages.namePlaceholder",
    defaultMessage: "Enter name",
  },
});
