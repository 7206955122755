import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { createCrop as action } from "store/crops/actions";
// api
import { createCrop as api } from "store/crops/api";
import { errorNotifications } from "utils/errorNotifications";

function* saga({ payload }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    yield put(action.success(response.data));
    payload?.callback(response);
  } catch (error) {
    yield call(payload.failure, error.response.data.data);
    payload.finallyCallback = undefined;
  } finally {
    yield put(action.fulfill());
    if (payload.finallyCallback) payload.finallyCallback();
  }
}

export default function* createCrop() {
  yield takeLatest(action.TRIGGER, saga);
}
