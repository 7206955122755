import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { createDirectory as action } from "store/directorys/actions";
// api
import { createDirectory as api } from "store/directorys/api";

function* saga({ payload }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    yield put(action.success(response.data));
    if (payload?.callback) payload?.callback(response.data);
  } catch (error) {
    console.log(error);
    yield call(payload.failure, error.response);
    payload.finallyCallback = undefined;
  } finally {
    yield put(action.fulfill());
    if (payload.finallyCallback) payload.finallyCallback();
  }
}

export default function* createDirectory() {
  yield takeLatest(action.TRIGGER, saga);
}
