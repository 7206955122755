import React from "react";
import styled from "./styled.module.scss";
import CardMenu from "components/CardMenu/CardMenu";
import moment from "moment";
import clientCardMessages from "components/ClientCard/ClientCardMessages";
import { FormattedMessage } from "react-intl";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { useDrag } from "react-dnd";

export interface IProject {
  created_at: string;
  id: number;
  lat: number;
  long: number;
  name: string;
  updated_at: string;
}

interface IProjectCard {
  project: IProject;
  onEdit: () => void;
  onDelete: () => void;
  inDirectory?: boolean;
}

const ProjectCard = ({ project, onEdit, onDelete, inDirectory = false }: IProjectCard) => {
  const intl = useIntl();
  const { client_id } = useParams();
  const navigate = useNavigate();

  const [{ isDragging }, drag] = useDrag(() => ({
    type: inDirectory ? "PROJECT_CARD_DIRECTORY" : "PROJECT_CARD",
    item: { id: project.id, inDirectory },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [inDirectory, project.id]);

  const navigateToProject = () => {
    document.body.style.overflow = "auto";

    navigate(
      ROUTES.STRUCTURES.replace(
        ":client_id",
        client_id as string
      ).replace(":project_id", String(project.id))
    );
  }

  return (
    <div className={styled.container} style={{ opacity: isDragging ? .5 : 1 }} ref={drag} onClick={navigateToProject}>
      <div>
        <div className={styled.name}>
          {project.name}
        </div>
        <span className={styled.date}>
          <FormattedMessage {...clientCardMessages.lastUpdate} />
          {": "}
          {moment.utc(project.updated_at).local().fromNow()}
        </span>
      </div>
      <Tooltip title={intl.formatMessage(commonMessages.delete)}>
        <CardMenu onEdit={onEdit} onDelete={onDelete} />
      </Tooltip>
    </div>
  );
};

export default ProjectCard;
