import { defineMessages } from "react-intl";

export default defineMessages({
  addRow: {
    id: "AddModalRowSteeringAlgorithmsTable.addRow",
    defaultMessage: "Add new rule",
  },
  editRow: {
    id: "AddModalRowSteeringAlgorithmsTable.editRow",
    defaultMessage: "Edit rule",
  },
  errorHourNotValid: {
    id: "AddModalRowSteeringAlgorithmsTable.errorHourNotValid",
    defaultMessage: "This hour is'nt valid",
  },
  errorHourEndBeforeStart: {
    id: "AddModalRowSteeringAlgorithmsTable.errorHourEndBeforeStart",
    defaultMessage: "End hour is before start one",
  },
  startHour: {
    id: "AddModalRowSteeringAlgorithmsTable.startHour",
    defaultMessage: "Start Hour",
  },
  endHour: {
    id: "AddModalRowSteeringAlgorithmsTable.endHour",
    defaultMessage: "End Hour",
  },
  selectType: {
    id: "AddModalRowSteeringAlgorithmsTable.selectType",
    defaultMessage: "Select rule's type",
  },
  selectState: {
    id: "AddModalRowSteeringAlgorithmsTable.selectState",
    defaultMessage: "Select rule's state",
  },
  AM: {
    id: "AddModalRowSteeringAlgorithmsTable.AM",
    defaultMessage: "AM",
  },
  PM: {
    id: "AddModalRowSteeringAlgorithmsTable.PM",
    defaultMessage: "PM",
  },
  objective: {
    id: "AddModalRowSteeringAlgorithmsTable.objective",
    defaultMessage: "Objective",
  },
});
