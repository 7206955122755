import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { deleteDirectory as action } from "store/directorys/actions";
// api
import { deleteDirectory as api } from "store/directorys/api";

function* saga({ payload }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    payload?.callback(response.data);
    yield put(action.success(response.data));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(action.fulfill());
  }
}

export default function* deleteDirectory() {
  yield takeLatest(action.TRIGGER, saga);
}
