import { defineMessages } from "react-intl";

export default defineMessages({
  myProfile: {
    id: "ProfileMessages.myProfile",
    defaultMessage: "My Profile",
  },
  settings: {
    id: "ProfileMessages.settings",
    defaultMessage: "Settings",
  },
  logout: {
    id: "ProfileMessages.logout",
    defaultMessage: "Logout",
  },
  language: {
    id: "ProfileMessages.language",
    defaultMessage: "Language",
  },
  addAlert: {
    id: "ProfileMessages.addAlert",
    defaultMessage: "Add Alert",
  },
  deleteAlert: {
    id: "ProfileMessages.deleteAlert",
    defaultMessage: "Delete Alert",
  },
  seeAlert: {
    id: "ProfileMessages.seeAlert",
    defaultMessage: "See Alert",
  },
});
