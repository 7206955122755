import { apiGET, apiPOST, apiDELETE, apiPATCH } from "store/services/api";

export const getDirectoryList = ({ clientId }) =>
  apiGET({
    url: `/clients/${clientId}/directory`,
  });

export const createDirectory = ({ clientId, ...data }) =>
  apiPOST({
    url: `/clients/${clientId}/directory/create`,
    data,
  });

export const editDirectory = ({ clientId, id, ...data }) =>
  apiPATCH({
    url: `/clients/${clientId}/directory/edit/${id}`,
    data,
  });

export const deleteDirectory = ({ clientId, id }) =>
  apiDELETE({
    url: `/clients/${clientId}/directory/delete/${id}`,
  });

export const linkProject = ({ clientId, id, ...data }) =>
  apiPOST({
    url: `/clients/${clientId}/directory/link/${id}`,
    data,
  });
