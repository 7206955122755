import { all, fork } from "redux-saga/effects";
// sagas
import getDirectoryList from "store/directorys/sagas/getDirectoryList";
import createDirectory from "store/directorys/sagas/createDirectory";
import editDirectory from "store/directorys/sagas/editDirectory";
import deleteDirectory from "store/directorys/sagas/deleteDirectory";
import linkProject from "store/directorys/sagas/linkProject";

export default function* auth() {
  yield all([
    fork(getDirectoryList),
    fork(createDirectory),
    fork(editDirectory),
    fork(deleteDirectory),
    fork(linkProject),
  ]);
}
