import commonMessages from "components/common/CommonMessages";
import styled from "./AddModalRowSteeringAlgorithmsTable.module.scss";
import ModalContainer from "components/ModalContainer/ModalContainer";
import {
  IRowField,
  IRowState,
  IRowType,
} from "pages/SteeringAlgorithmsCreate/SteeringAlgorithmsCreateTypes";
import { FormattedMessage, useIntl } from "react-intl";
import { AddModalRowSteeringAlgorithmsTableHooks } from "./AddModalRowSteeringAlgorithmsTableHooks";
import AddModalRowSteeringAlgorithmsTableMessages from "./AddModalRowSteeringAlgorithmsTableMessages";
import Button from "components/common/button/Button";
import Select from "components/common/select/Select";
import Input from "components/common/input/Input";
import { useEffect, useState } from "react";
import Switch from "components/common/switch/Switch";
import { ReactComponent as FormatHourIcon } from "assets/icons/24-hours.svg";

function AddModalRowSteeringAlgorithmsTable(props: {
  show: boolean;
  onHide: () => void;
  onSave: (v: IRowField) => void;
  onEdit: (v: IRowField) => void;
  rows: Array<IRowField>;
  isEdit: boolean;
  currentRow: IRowField | undefined;
  currentPeriodId: number;
  title: string;
}) {
  const intl = useIntl();

  const {
    models: { formik },
  } = AddModalRowSteeringAlgorithmsTableHooks({
    onSave: props.onSave,
    rows: props.rows,
    isEdit: props.isEdit,
    onEdit: props.onEdit,
    currentPeriodId: props.currentPeriodId,
    currentRow: props.currentRow,
    show: props.show,
  });

  const [input1, setInput1] = useState(4);
  const [input2, setInput2] = useState(7);
  const [hourState1, setHourState1] = useState(false);
  const [hourState2, setHourState2] = useState(false);

  const [errorHourStart, setErrorHourStart] = useState("");
  const [errorHourEnd, setErrorHourEnd] = useState("");

  useEffect(() => {
    if (props.show && props.currentRow) {
      if (props.currentRow.value.hourStart >= 12) {
        setInput1(props.currentRow.value.hourStart == 12 ? 12 : props.currentRow.value.hourStart - 12);
        setHourState1(true);
      } else if (props.currentRow.value.hourStart == 0) {
        setInput1(12);
        setHourState1(false);
      } else {
        setInput1(props.currentRow.value.hourStart);
        setHourState1(false);
      }
      if (props.currentRow.value.hourEnd >= 12) {
        setInput2(props.currentRow.value.hourEnd == 12 ? 12 : props.currentRow.value.hourEnd - 12);
        setHourState2(true);
      } else if (props.currentRow.value.hourEnd == 0) {
        setInput2(12);
        setHourState2(true);
      } else {
        setInput2(props.currentRow.value.hourEnd);
        setHourState2(false);
      }
    } else {
      setInput1(4);
      setInput2(7);
      setHourState1(false);
      setHourState2(false);
    }
    setErrorHourStart("");
    setErrorHourEnd("");
  }, [props.show, props.currentRow]);

  const getOptionsType = () => {
    const ret = [];

    for (const values in IRowType) {
      ret.push({
        label: values,
        value: values,
      });
    }

    return ret.filter(val => val.value != IRowType.dli_threshold);
  };

  const getOptionsState = () => {
    const ret = [];

    for (const values in IRowState) {
      ret.push({
        label: values,
        value: values,
      });
    }

    return ret;
  };

  const onSave = () => {
    setErrorHourStart("");
    setErrorHourEnd("");
    if (Number(input1) < 1 || Number(input1) > 12) {
      setErrorHourStart(
        AddModalRowSteeringAlgorithmsTableMessages.errorHourNotValid
          .defaultMessage
      );
      return;
    }
    if (Number(input2) < 1 || Number(input2) > 12) {
      setErrorHourEnd(
        AddModalRowSteeringAlgorithmsTableMessages.errorHourNotValid
          .defaultMessage
      );
      return;
    }
    if (
      hourState1 &&
      !hourState2
    ) {
      setErrorHourStart(
        AddModalRowSteeringAlgorithmsTableMessages.errorHourEndBeforeStart
          .defaultMessage
      );
      setErrorHourEnd(
        AddModalRowSteeringAlgorithmsTableMessages.errorHourEndBeforeStart
          .defaultMessage
      );
      return;
    } else if (
      hourState1 == hourState2 &&
      ((Number(input2) < Number(input1) && Number(input1) != 12) || Number(input2) == 12)
    ) {
      setErrorHourStart(
        AddModalRowSteeringAlgorithmsTableMessages.errorHourEndBeforeStart
          .defaultMessage
      );
      setErrorHourEnd(
        AddModalRowSteeringAlgorithmsTableMessages.errorHourEndBeforeStart
          .defaultMessage
      );
      return;
    }
    props.isEdit && props.currentRow
      ? props.onEdit({
        ...props.currentRow,
        value: formik.values.value,
        type: formik.values.type!,
        state: formik.values.state!,
      })
      : props.onSave({
        ...formik.values,
        id: props.isEdit ? props.currentRow!.id : props.rows.length,
        type: formik.values.type!,
        state: formik.values.state!,
        period_id: props.currentPeriodId,
      });
  };

  const ValueFromType = (props: { type: string }) => {
    const onChangeAMPM = (
      v: boolean,
      setHourState: Function,
      valueToChange: "value.hourStart" | "value.hourEnd"
    ) => {
      setHourState(v);
      switch (valueToChange) {
        case "value.hourStart": {
          setErrorHourStart("");
          formik.setFieldValue(
            valueToChange,
            Number(formik.values.value.hourStart) + (v ? 12 : -12)
          );
          return;
        }
        case "value.hourEnd": {
          setErrorHourEnd("");
          formik.setFieldValue(
            valueToChange,
            Number(formik.values.value.hourEnd) + (v ? 12 : -12)
          );
          return;
        }
      }
    };

    const onChangeHour = (
      v: any,
      valueToChange: "value.hourStart" | "value.hourEnd",
      setInputValue: Function
    ) => {
      switch (valueToChange) {
        case "value.hourStart":
          setErrorHourStart("");
          break;
        case "value.hourEnd":
          setErrorHourEnd("");
          break;
      }
      var value: number = v.currentTarget.value;
      if (value > 12) value = 12;
      else if (value < 0) value = 0;

      setInputValue(value);

      if (
        value == 12 &&
        ((valueToChange == "value.hourStart" && !hourState1) ||
          (valueToChange == "value.hourEnd" && !hourState2))
      )
        value = 0;
      else if (
        value == 12 &&
        ((valueToChange == "value.hourStart" && hourState1) ||
          (valueToChange == "value.hourEnd" && hourState2))
      )
        value = 12;
      else if (
        (valueToChange == "value.hourStart" && hourState1) ||
        (valueToChange == "value.hourEnd" && hourState2)
      )
        value = Number(value) + 12;

      formik.setFieldValue(valueToChange, value);
    };

    switch (props.type) {
      case IRowType.time_slot:
        return (
          <div>
            <Input
              id="value.hourStart"
              name="value.hourStart"
              label={intl.formatMessage(
                AddModalRowSteeringAlgorithmsTableMessages.startHour
              )}
              type="number"
              min={1}
              max={12}
              onChange={(v) => onChangeHour(v, "value.hourStart", setInput1)}
              value={input1}
              className={!!errorHourStart ? styled["input-invalid"] : ""}
              afterIcon={
                <div className={styled.format24H}>
                  <div className={styled.icon}>
                    <FormatHourIcon />
                  </div>
                  {formik.values.value.hourStart}h
                </div>
              }
              rightContent={
                <div style={{ marginInline: "2%" }}>
                  <Switch
                    label1={intl.formatMessage(
                      AddModalRowSteeringAlgorithmsTableMessages.AM
                    )}
                    label2={intl.formatMessage(
                      AddModalRowSteeringAlgorithmsTableMessages.PM
                    )}
                    value={hourState1}
                    onChange={(v) =>
                      onChangeAMPM(v, setHourState1, "value.hourStart")
                    }
                  />
                </div>
              }
              errorMessage={errorHourStart ? errorHourStart : ""}
            />

            <Input
              id="value.hourEnd"
              name="value.hourEnd"
              label={intl.formatMessage(
                AddModalRowSteeringAlgorithmsTableMessages.endHour
              )}
              type="number"
              min={1}
              max={12}
              onChange={(v) => onChangeHour(v, "value.hourEnd", setInput2)}
              value={input2}
              className={!!errorHourEnd ? styled["input-invalid"] : ""}
              afterIcon={
                <div className={styled.format24H}>
                  <div className={styled.icon}>
                    <FormatHourIcon />
                  </div>
                  {formik.values.value.hourEnd}h
                </div>
              }
              rightContent={
                <div style={{ marginInline: "2%" }}>
                  <Switch
                    label1={intl.formatMessage(
                      AddModalRowSteeringAlgorithmsTableMessages.AM
                    )}
                    label2={intl.formatMessage(
                      AddModalRowSteeringAlgorithmsTableMessages.PM
                    )}
                    value={hourState2}
                    onChange={(v) =>
                      onChangeAMPM(v, setHourState2, "value.hourEnd")
                    }
                  />
                </div>
              }
              errorMessage={errorHourEnd ? errorHourEnd : ""}
            />
          </div>
        );
      case IRowType.irradiance_fraction:
        return (
          <Input
            id="value.objective"
            name="value.objective"
            label={intl.formatMessage(
              AddModalRowSteeringAlgorithmsTableMessages.objective
            )}
            type="number"
            min={0}
            max={100}
            onChange={(v) => formik.setFieldValue("value.objective", Number(v.target.value))}
            value={formik.values.value.objective}
          // className={!!errorHourStart ? styled["input-invalid"] : ""}
          />
        );
      case IRowType.dli_threshold:
        return (
          <Input
            id="value.objective"
            name="value.objective"
            label={intl.formatMessage(
              AddModalRowSteeringAlgorithmsTableMessages.objective
            )}
            type="number"
            min={0}
            onChange={(v) => formik.setFieldValue("value.objective", Number(v.target.value))}
            value={formik.values.value.objective}
          // className={!!errorHourStart ? styled["input-invalid"] : ""}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <ModalContainer show={props.show} onHide={props.onHide} title={props.title}>
      <form className={styled.form} onSubmit={formik.handleSubmit}>
        <div style={{ display: "flex" }}>
          <Select
            label={intl.formatMessage(
              AddModalRowSteeringAlgorithmsTableMessages.selectType
            )}
            options={getOptionsType()}
            defaultValue={
              props.isEdit && props.currentRow
                ? getOptionsType().find(
                  (option) => option.value == props.currentRow!.type
                )
                : getOptionsType()[0]
            }
            onChange={(e: any) => {
              formik.setFieldValue("type", e.value);
            }}
          />
        </div>

        {ValueFromType({ type: formik.values.type! })}

        <div className={styled.row}>
          <Button onClick={onSave}>
            <FormattedMessage {...commonMessages.add} />
          </Button>
          <Button variant="text" onClick={props.onHide}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
}

export { AddModalRowSteeringAlgorithmsTable };
