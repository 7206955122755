import React, { useState, useEffect } from "react";
import styled from "./styled.module.scss";
import CardMenu from "components/CardMenu/CardMenu";
import moment from "moment";
import clientCardMessages from "components/ClientCard/ClientCardMessages";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import clsx from "clsx";
import DirectoryCardMessages from "./DirectoryCardMessages";
import { useDrop } from "react-dnd";
import ProjectCard, { IProject } from "components/ProjectCard/ProjectCard";

export interface IDirectory {
  id: number;
  name: string;
  directory_links: any[];
  created_at: string;
  updated_at: string;
}

interface IDirectoryCard {
  directory: IDirectory;
  onEdit: () => void;
  onDelete: () => void;
  onDrop: (item: any) => void;
  projects: IProject[];
  onEditProject: (id: number) => () => void;
  onDeleteProject: (id: number) => () => void;
}

const DirectoryCard = ({ directory, onEdit, onDelete, onDrop, projects, onEditProject, onDeleteProject }: IDirectoryCard) => {
  const intl = useIntl();
  const { client_id } = useParams();

  const [open, setOpen] = useState(!directory.directory_links.length);

  useEffect(() => { console.log(directory.id, 'refresh') }, [])

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: ["PROJECT_CARD", "PROJECT_CARD_DIRECTORY"],
    canDrop: (item: any) => {
      return directory.directory_links.find(link => link.project_id === item.id) == undefined;
    },
    drop: (item) => {
      onDrop(item);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }), [directory]);

  return (
    <div
      ref={drop}
      className={clsx(styled.container, { [styled.dropHighlight]: isOver && canDrop })}
      onClick={() => setOpen(prev => !prev)}
    >
      <div className={styled.infoContainer}>
        <div>
          <div className={styled.name}>
            <Icon name={ICON_NAMES_ENUM.folder} className={styled.icon} />
            {directory.name}
          </div>
          <span className={styled.date}>
            <FormattedMessage {...clientCardMessages.lastUpdate} />
            {": "}
            {moment.utc(directory.updated_at).local().fromNow()}
          </span>
        </div>
        <div className={styled.spaceBetween}>
          <Tooltip title={intl.formatMessage(commonMessages.delete)}>
            <CardMenu onEdit={onEdit} onDelete={onDelete} />
          </Tooltip>
          <Icon
            name={open ? ICON_NAMES_ENUM.eyes_close : ICON_NAMES_ENUM.eyes_open}
            className={styled.icon}
          />
        </div>
      </div>
      <div className={clsx(styled.projectList, { [styled.open]: open })}>
        {!directory.directory_links.length && (
          <div className={styled.emptyZone}>
            <div className={styled.dragZone} />
            <FormattedMessage {...DirectoryCardMessages.dragHere} />
          </div>
        )}
        <div className={styled.list}>
          {!!directory.directory_links.length && projects.map((project: any, index) => <ProjectCard
            key={index}
            project={project}
            onDelete={onDeleteProject(project.id)}
            onEdit={onEditProject(project.id)}
            inDirectory
          />)}
        </div>
      </div>
    </div>
  );
};

export default DirectoryCard;
