import { object, string, array, number, ValidationError } from "yup";
import { IntlShape } from "react-intl";
import validationMessages from "components/common/ValidationMessages";
import cropCreateMessages from "pages/CropsCreate/CropCreateMessages";
import { values } from "lodash";

const CropSchema = (intl: IntlShape) =>
  object()
    .shape({
      name: string()
        .max(
          50,
          intl.formatMessage(validationMessages.maxLength, { number: 50 })
        )
        .required(intl.formatMessage(validationMessages.required)),
      crop_type: string().required(
        intl.formatMessage(validationMessages.required)
      ),
      sowing_date: string().nullable(),
      base_degree_day: number().nullable(),
      temperature_threshold: number().min(0, intl.formatMessage(validationMessages.minValue, { number: 0 })).max(50, intl.formatMessage(validationMessages.maxValue, { number: 50 })).required(intl.formatMessage(validationMessages.required)),
      period: array()
        .of(
          object().shape({
            name: string()
              .max(
                50,
                intl.formatMessage(validationMessages.maxLength, { number: 50 })
              )
              .required(intl.formatMessage(validationMessages.required)),
            crop_height: number().required(
              intl.formatMessage(validationMessages.required)
            ),
            starting: string(),
            ending: string(),
            date: object(),
            crop_coefficient: number().required(
              intl.formatMessage(validationMessages.required)
            ),
            rank: number().required(
              intl.formatMessage(validationMessages.required)
            ),
          })
        )
        .min(1, intl.formatMessage(validationMessages.required))
        .test("unique-periods-names", (periods, context) => {
          const errors = Array();
          if (periods) {
            periods.forEach((period, index) => {
              const arr = [...periods];
              for (let i = 0; i < periods.length; i++) {
                if (i != index && periods[i].name == period.name)
                  errors.push(
                    context.createError({
                      path: `period[${index}].name`,
                      message: intl.formatMessage(
                        validationMessages.uniqueNames
                      ),
                    })
                  );
              }
            });
          }
          if (errors.length) throw new ValidationError(errors);
          return true;
        })
        .test("server-test-periods-names", (periods, context) => {
          const errors = Array();
          if (periods) {
            periods.forEach((period, index) => {
              ["__1", "__2"].forEach((suffix) => {
                if (period.name?.endsWith(suffix))
                  errors.push(
                    context.createError({
                      path: `period[${index}].name`,
                      message: intl.formatMessage(
                        validationMessages.serverSuffix,
                        { suffix: suffix }
                      ),
                    })
                  );
              });
            });
          }
          if (errors.length) throw new ValidationError(errors);
          return true;
        })
        .test("end_date_and_start_date_not_equals", (periods, context) => {
          const errors = Array();
          if (periods) {
            periods.forEach((period, index) => {
              if (period.starting && period.ending)
                if (period.starting === period.ending) {
                  errors.push(
                      context.createError({
                        path: `period[${index}].ending`,
                        message: intl.formatMessage(
                          validationMessages.invalidDates
                        ),
                      })
                    );
                  errors.push(
                    context.createError({
                      path: `period[${index}].starting`,
                      message: intl.formatMessage(
                        validationMessages.invalidDates
                      ),
                    })
                  );
                }
            });
          }
          if (errors.length) throw new ValidationError(errors);
          return true;
        }),
    })
    .test("crop-type-test", (value, context) => {
      const errors = Array();
      if (value.crop_type == "degreeDays") {
        if (value.base_degree_day == undefined) {
          errors.push(
            context.createError({
              path: "base_degree_day",
              message: intl.formatMessage(validationMessages.required),
            })
          );
        } else if (value.base_degree_day < 0) {
          errors.push(
            context.createError({
              path: "base_degree_day",
              message: intl.formatMessage(validationMessages.minValue, {
                number: 0,
              }),
            })
          );
        }
        if (value.sowing_date == null) {
          errors.push(
            context.createError({
              path: "sowing_date",
              message: intl.formatMessage(validationMessages.required),
            })
          );
        }
      }
      if (errors.length) throw new ValidationError(errors);
      return true;
    });

export default CropSchema;
