import React, { Fragment, useEffect } from "react";
import styled from "components/SimulationDropItem/styled.module.scss";
import Line from "components/SimulationDropItem/Line";
import { ICrops } from "pages/Crops/Crops";
import moment from "moment";
import {
  IRowField,
  IRowType,
  IDbSteeringAlgorithm,
  IValues,
} from "pages/SteeringAlgorithmsCreate/SteeringAlgorithmsCreateTypes";
import useSingeData from "components/SimulationBody/useSingeData";

const SteeringAlgorithmsRenderList = ({
  selected,
}: {
  selected: IDbSteeringAlgorithm;
}) => {
  const { crop, getCrop, getCopyCrop } = useSingeData();

  useEffect(() => {
    if (selected.crop_id)
      getCrop(selected.crop_id, false).catch((err) => {
        getCopyCrop(Number(selected.crop_id));
      });
  }, [selected.crop_id]);

  const valueFromType = (type: IRowType, value: string) => {
    const temp_value = JSON.parse(value);

    switch (type) {
      case IRowType.time_slot:
        return (
          <>
            <Line
              title="Start Hour"
              value={
                temp_value["hourStart"] < 12
                  ? temp_value["hourStart"] == 0
                    ? "12 AM"
                    : `${temp_value["hourStart"]} AM`
                  : temp_value["hourStart"] == 12
                    ? "12 PM"
                    : `${temp_value["hourStart"] - 12} PM`
              }
            />
            <Line
              title="End Hour"
              value={
                temp_value["hourEnd"] < 12
                  ? temp_value["hourEnd"] == 0
                    ? "12 AM"
                    : `${temp_value["hourEnd"]} AM`
                  : temp_value["hourEnd"] == 12
                    ? "12 PM"
                    : `${temp_value["hourEnd"] - 12} PM`
              }
            />
          </>
        );
      case IRowType.irradiance_fraction:
      case IRowType.dli_threshold:
        return (
          <Line
            title="Objective"
            value={temp_value["objective"]}
          />
        );
    }
  };

  if (!crop) return <></>;

  return (
    <ul className={styled.ul}>
      {selected.rows.map((row, i) => {
        return (
          <Fragment key={i}>
            {i > 0 && <br />}
            <Line
              title="Current period"
              value={
                crop.periods.find((period) => period.id == row.period_id) ? crop.periods.find((period) => period.id == row.period_id)!.name : ''
              }
            />
            <Line title="Rules type" value={row.row_type} />
            <Line title="Rules state" value={row.row_state} />
            {valueFromType(row.row_type, row.value)}
          </Fragment>
        );
      })}
    </ul>
  );
};

export default SteeringAlgorithmsRenderList;
