import { defineMessages } from "react-intl";

export default defineMessages({
  generalSetting: {
    id: "BatchesWaterBalanceBodyMessages.generalSetting",
    defaultMessage: "General Settings",
  },
  baseWaterBalance: {
    id: "BatchesWaterBalanceBodyMessages.baseWaterBalance",
    defaultMessage: "Base Water Balance",
  },
  frequency: {
    id: "BatchesWaterBalanceBodyMessages.frequency",
    defaultMessage: "Simulation Frequency",
  },
  interest: {
    id: "BatchesWaterBalanceBodyMessages.interest",
    defaultMessage: "Parameters of interest",
  },
  addRow: {
    id: "BatchesWaterBalanceBodyMessages.addRow",
    defaultMessage: "Add row",
  },
});
