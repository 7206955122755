import { defineMessages } from "react-intl";

export default defineMessages({
  simulationSetting: { 
    id: "SimulationCreateMessages.simulationSetting",
    defaultMessage: "Simulation Setting",
  },
  runSimulation: {
    id: "SimulationCreateMessages.runBatchSimulation",
    defaultMessage: "Run Simulation",
  },
  description: {
    id: "SimulationCreateMessages.description",
    defaultMessage: "Description",
  },
  resolution: {
    id: "SimulationCreateMessages.resolution",
    defaultMessage: "Resolution (pts/m)",
  },
  simulationFrequency: {
    id: "SimulationCreateMessages.simulationFrequency",
    defaultMessage: "Simulation Frequency",
  },
  maxScale: {
    id: "SimulationCreateMessages.maxScale",
    defaultMessage: "Max Scale Value",
  },
  setMaxPlot: {
    id: "SimulationCreateMessages.setMaxPlot",
    defaultMessage: "Set max plot scale value",
  },
  simulationName: {
    id: "SimulationCreateMessages.simulationName",
    defaultMessage: "Simulation Name",
  },
  simulationUnnamed: {
    id: "SimulationCreateMessages.simulationUnnamed",
    defaultMessage: "Simulation Unnamed",
  },
  grasslandPrimaryYieldCalculation: {
    id: "SimulationCreateMessages.grasslandPrimaryYieldCalculation",
    defaultMessage: "Grassland Yield Calculation",
  },
  edgeEffect: {
    id: "SimulationCreateMessages.edgeEffect",
    defaultMessage: "Edge Effect",
  },
  raygrassFraction: {
    id: "SimulationCreateMessages.raygrassFraction",
    defaultMessage: "Grass type raygrass (%)",
  },
  fetuqueFraction: {
    id: "SimulationCreateMessages.fetuqueFraction",
    defaultMessage: "Grass type fetuque (%)",
  },
  dactyleFraction: {
    id: "SimulationCreateMessages.dactyleFraction",
    defaultMessage: "Grass type dactyle (%)",
  },
  treffleFraction: {
    id: "SimulationCreateMessages.treffleFraction",
    defaultMessage: "Grass type treffle (%)",
  },
});
