import { apiGET, apiPOST, apiDELETE, apiPATCH } from "store/services/api";

export const getRecapTable = ({ clientId, projectId, id, ...data }) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/recap_table/${id}`,
  });

export const getAnalisysTable = ({ clientId, projectId, id, ...data }) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/analisys_table/${id}`,
  });

export const getIrradianceTable = ({ clientId, projectId, id, ...data }) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/irradiance_table/${id}`,
  });

export const getProductionTable = ({ clientId, projectId, id, ...data }) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/production_table/${id}`,
  });

export const getETPTable = ({ clientId, projectId, id, ...data }) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/ETP_table/${id}`,
  });

export const getAvailableWaterStockTable = ({
  clientId,
  projectId,
  id,
  ...data
}) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/available_water_stock_table/${id}`,
  });

export const getWaterStressTable = ({ clientId, projectId, id, ...data }) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/water_stress_table/${id}`,
  });

export const getWaterConsumptionGraph = ({
  clientId,
  projectId,
  id,
  ...data
}) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/water_consumption_graph/${id}`,
  });

export const getWaterConsumptionTable = ({
  clientId,
  projectId,
  id,
  ...data
}) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/water_consumption_table/${id}`,
  });

export const getWaterDeficitGraph = ({ clientId, projectId, id, ...data }) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/water_deficit_graph/${id}`,
  });

export const getWaterDeficitTable = ({ clientId, projectId, id, ...data }) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/water_deficit_table/${id}`,
  });

export const getThermalStressTable = ({ clientId, projectId, id, ...data }) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/thermal_stress_table/${id}`,
  });

export const getWaterBalanceBatchAnalysisTable = ({
  clientId,
  projectId,
  batch_water_balance_id,
  id,
  ...data
}) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/batch_water_balance_analysis_tables/${batch_water_balance_id}/${id}`,
  });

export const getGrasslandGraph = ({ clientId, projectId, id, ...data }) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/grassland_graph/${id}`,
  });

export const getGrasslandTable = ({ clientId, projectId, id, ...data }) =>
apiGET({
  url: `clients/${clientId}/projects/${projectId}/grassland_table/${id}`,
});