import React, { useEffect, useState } from "react";
import styled from "./SteeringAlgorithmsEdit.module.scss";
import EditName from "components/EditName/EditName";
import Button from "components/common/button/Button";
import commonMessages from "components/common/CommonMessages";
import { FormattedMessage, useIntl } from "react-intl";
import steeringAlgorithmsCreateMessages from "pages/SteeringAlgorithmsCreate/SteeringAlgorithmsCreateMessages";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import clsx from "clsx";
import { AddModalPeriodCropsTable } from "components/AddModalPeriodCropsTable/AddModalPeriodCropsTable";
import MainContent from "components/MainContent/MainContent";
import addModalPeriodCropsTable from "components/AddModalPeriodCropsTable/AddModalPeriodCropsTableMessages";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import { usePrompt } from "hooks/usePromt";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import { getCropsItem } from "store/crops/selectors";
import { SpanError } from "components/common/span-error/SpanError";
import { SteeringAlgorithmsEditHook } from "./SteeringAlgorithmsEditHook";
import { getSteeringAlgorithmsItem } from "store/steering_algorithms/selectors";
import { AddModalRowSteeringAlgorithmsTable } from "components/AddModalRowSteeringAlgorithmsTable/AddModalRowSteeringAlgorithmsTable";
import AddModalRowSteeringAlgorithmsTableMessages from "components/AddModalRowSteeringAlgorithmsTable/AddModalRowSteeringAlgorithmsTableMessages";
import {
  IRowState,
  IRowType,
} from "pages/SteeringAlgorithmsCreate/SteeringAlgorithmsCreateTypes";
import useSingeData from "components/SimulationBody/useSingeData";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import CropsCreateMessages from "pages/CropsCreate/CropCreateMessages";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { downloadFile } from "utils/downloadFile";
import Input from "components/common/input/Input";
import Tooltip from "@mui/material/Tooltip/Tooltip";

function SteeringAlgorithmsEdit() {
  const navigate = useNavigate();
  const intl = useIntl();
  const [show, onShow] = useState(false);
  const steering_algorithm = useSelector(getSteeringAlgorithmsItem);
  const { client_id, project_id, id } = useParams();
  const [periodToAdd, setPeriodToAdd] = useState(0);
  const {
    models: { IsOpenModal, isEditFunc, currentRow, formik },
    commands: {
      onSaveRow,
      onDeleteSteeringAlgorithm,
      onShowModal,
      onAddNewRow,
      onEditSave,
      onEditField,
      onHideModal,
      onDeleteRow,
      onBack,
    },
  } = SteeringAlgorithmsEditHook();

  const { crop, getCrop } = useSingeData();

  const monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  const displayDate = (date: string | undefined) => {
    if (!date)
      return ''
    const month = monthList[Number(date.substring(5).split('-')[0]) - 1]
    return `${date.substring(5).split('-')[1]}  ${month}`
  }

  useEffect(() => {
    if (formik.values.crop_id) getCrop(formik.values.crop_id);
  }, [formik.values.crop_id]);

  const isDataChanged = (): boolean => {
    if (steering_algorithm) {
      if (formik.values.name != steering_algorithm.name) return true;
      if (formik.values.row.length != steering_algorithm.rows.length)
        return true;
      if (
        formik.values.row
          .map(
            (row, index) => row.type != steering_algorithm.rows[index].row_type
          )
          .find((item) => item)
      )
        return true;
      if (
        formik.values.row
          .map(
            (row, index) => row.state != steering_algorithm.rows[index].row_state
          )
          .find((item) => item)
      )
        return true;
      if (
        formik.values.row
          .map(
            (row, index) => {
              if (row.type == "time_slot") {
                if (row.value["hourStart"] !=
                  JSON.parse(steering_algorithm.rows[index].value)["hourStart"] ||
                  row.value["hourEnd"] !=
                  JSON.parse(steering_algorithm.rows[index].value)["hourEnd"])
                  return true
                else return false
              }
              else if (row.type == 'irradiance_fraction' || row.type == "dli_threshold") {
                if (row.value["objective"] !=
                  JSON.parse(steering_algorithm.rows[index].value)["objective"])
                  return true
                else return false
              }
              else return false
            }
          )
          .find((item: boolean) => item)
      )
        return true;
    }

    return false;
  };

  const [isFileLoading, setIsFileLoading] = useState(false);

  usePrompt(isDataChanged() && !formik.isSubmitting && !show);

  return (
    <form onSubmit={formik.handleSubmit}>
      <MainContent
        header={
          <>
            <EditName
              onFocus={(event) => {
                event.target.select();
              }}
              placeholder={intl.formatMessage(
                steeringAlgorithmsCreateMessages.steeringAlgorithmName
              )}
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              errorMessage={formik.touched.name ? formik.errors.name : ""}
              onBlur={formik.handleBlur}
            />
            <div className={styled.flex}>
              <Button
                onClick={() => onShow(true)}
                iconBefore={<Icon name={ICON_NAMES_ENUM.delete} />}
                variant="text"
                className={styled.deleteButton}
              >
                <FormattedMessage {...commonMessages.delete} />
              </Button>
              <Button
                variant="text"
                onClick={() =>
                  navigate(
                    ROUTES.STEERING_ALGORITHM.replace(
                      ":client_id",
                      client_id as string
                    ).replace(":project_id", project_id as string)
                  )
                }
              >
                <FormattedMessage {...commonMessages.cancel} />
              </Button>
              <Button
                iconBefore={<Icon name={ICON_NAMES_ENUM.save} />}
                type="submit"
                isLoading={formik.isSubmitting}
                disabled={!isDataChanged()}
              >
                <FormattedMessage {...commonMessages.save} />
              </Button>
            </div>
          </>
        }
      >
        {crop && !crop.periods[0].start_date && (
          <div className={styled.degreeDaysCalculation}>
            <h2 className={styled["section-title"]}>
              <FormattedMessage
                {...CropsCreateMessages.degreeDaysCalculation}
              />
            </h2>
            <div className={styled.flex}>
              <div className={styled.degreeInfoElement}>
                <Tooltip
                  title={intl.formatMessage(CropsCreateMessages.sowingDateHelp)}
                  placement="top"
                >
                  <h3 className={styled["selector-title"]}>
                    <FormattedMessage {...CropsCreateMessages.sowingDate} />
                    <div className={styled["help-icon"]}>
                      <Icon
                        name={ICON_NAMES_ENUM.help_circle}
                        className={styled.file__icon}
                      />
                    </div>
                  </h3>
                </Tooltip>
                <Input
                  id="sowing_date"
                  disabled
                  onChange={() => { }}
                  value={displayDate(crop.periods[0]!.sowing_date)}
                />
              </div>
              <div className={styled.degreeInfoElement}>
                <h3 className={styled["selector-title"]}>
                  <FormattedMessage {...CropsCreateMessages.baseTemperature} />
                </h3>
                <Input
                  type="number"
                  disabled
                  integerNumber
                  id="base_degree_day"
                  value={crop.periods[0]!.base_degree_day}
                />
              </div>
            </div>
          </div>
        )}
        {crop && (
          <div className={styled["table-container"]}>
            <table
              className={clsx(styled.table, {
                [styled.error]: formik.errors.row,
              })}
            >
              <thead>
                <tr>
                  <th>
                    <div className={styled.title}>
                      <FormattedMessage
                        {...steeringAlgorithmsCreateMessages.stageName}
                      />
                    </div>
                  </th>
                  <th>
                    <div className={styled.title}>
                      {crop.periods[0].start_date != null ? (
                        <FormattedMessage
                          {...steeringAlgorithmsCreateMessages.periodStart}
                        />
                      ) : (
                        <FormattedMessage
                          {...CropsCreateMessages.startDegreeDay}
                        />
                      )}
                    </div>
                  </th>
                  <th>
                    <div className={styled.title}>
                      {crop.periods[0].start_date != null ? (
                        <FormattedMessage
                          {...steeringAlgorithmsCreateMessages.periodEnd}
                        />
                      ) : (
                        <FormattedMessage
                          {...CropsCreateMessages.endDegreeDay}
                        />
                      )}
                    </div>
                  </th>
                  <th>
                    <div className={styled.title}>
                      <FormattedMessage
                        {...steeringAlgorithmsCreateMessages.rules}
                      />
                    </div>
                  </th>
                  <th>
                    <div className={styled.title}> </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {crop.periods
                  .map((period) => (
                    <tr key={period.id.toString()}>
                      <td>
                        <div className={styled["td-element"]}>
                          {period.name}
                        </div>
                      </td>
                      <td>
                        <div className={styled["td-element"]}>
                          {crop.periods[0].start_date != null
                            ? displayDate(period.start_date)
                            : period.start_degree_day}
                        </div>
                      </td>
                      <td>
                        <div className={styled["td-element"]}>
                          {crop.periods[0].start_date != null
                            ? displayDate(period.end_date)
                            : period.end_degree_day}
                        </div>
                      </td>

                      {
                        <td>
                          <div className={styled["td-list"]}>
                            {formik.values.row
                              .filter((row) => row.period_id == period.id)
                              .map((row, index) => {
                                var ret;
                                switch (row.type) {
                                  case IRowType.time_slot: {
                                    ret = (
                                      <li key={`${period.id}+${index}`}>
                                        {`Max ${row.state ==
                                          IRowState.max_irradiance
                                          ? "irradiance"
                                          : "shade"
                                          } between ${row.value.hourStart < 12
                                            ? row.value.hourStart == 0
                                              ? "12AM"
                                              : `${row.value.hourStart}AM`
                                            : row.value.hourStart == 12
                                              ? "12PM"
                                              : `${row.value.hourStart - 12
                                              }PM`
                                          } and ${row.value.hourEnd < 12
                                            ? row.value.hourEnd == 0
                                              ? "12AM"
                                              : `${row.value.hourEnd}AM`
                                            : row.value.hourEnd == 12
                                              ? "12PM"
                                              : `${row.value.hourEnd - 12}PM`
                                          }.`}
                                        <button
                                          className={styled.button}
                                          onClick={() => onEditField(row)}
                                          type="button"
                                        >
                                          <Edit />
                                        </button>
                                        <span className={styled.vertical} />
                                        <button
                                          type="button"
                                          className={clsx(
                                            styled.button,
                                            styled.red
                                          )}
                                          onClick={() => onDeleteRow(row.id)}
                                        >
                                          <Delete />
                                        </button>
                                      </li>
                                    );
                                  }
                                    break
                                  case IRowType.irradiance_fraction: {
                                    ret = (
                                      <li key={`${period.id}+${index}`}>
                                        {`Irradiance fraction of ${row.value.objective}%`}
                                        <button
                                          className={styled.button}
                                          onClick={() => onEditField(row)}
                                          type="button"
                                        >
                                          <Edit />
                                        </button>
                                        <span className={styled.vertical} />
                                        <button
                                          type="button"
                                          className={clsx(
                                            styled.button,
                                            styled.red
                                          )}
                                          onClick={() => onDeleteRow(row.id)}
                                        >
                                          <Delete />
                                        </button>
                                      </li>
                                    );
                                  }
                                    break
                                  case IRowType.dli_threshold: {
                                    ret = (
                                      <li key={`${period.id}+${index}`}>
                                        {`DLI threshold of ${row.value.objective}`}
                                        <button
                                          className={styled.button}
                                          onClick={() => onEditField(row)}
                                          type="button"
                                        >
                                          <Edit />
                                        </button>
                                        <span className={styled.vertical} />
                                        <button
                                          type="button"
                                          className={clsx(
                                            styled.button,
                                            styled.red
                                          )}
                                          onClick={() => onDeleteRow(row.id)}
                                        >
                                          <Delete />
                                        </button>
                                      </li>
                                    );
                                  }
                                    break


                                }
                                return ret;
                              })}
                          </div>
                        </td>
                      }
                      <td>
                        <div className={styled["td-element"]}>
                          <button
                            className={styled.button}
                            onClick={() => {
                              onAddNewRow();
                              setPeriodToAdd(period.id);
                            }}
                            type="button"
                          >
                            <Icon name={ICON_NAMES_ENUM.plus_circle} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {crop && (
          <SpanError
            errorMessage={formik.touched.row && (formik.errors.row as string)}
          />
        )}

        <AddModalRowSteeringAlgorithmsTable
          title={
            isEditFunc
              ? intl.formatMessage(
                AddModalRowSteeringAlgorithmsTableMessages.editRow
              )
              : intl.formatMessage(
                AddModalRowSteeringAlgorithmsTableMessages.addRow
              )
          }
          isEdit={isEditFunc}
          show={IsOpenModal}
          onHide={onHideModal}
          onSave={onSaveRow}
          onEdit={onEditSave}
          rows={formik.values.row}
          currentRow={currentRow}
          currentPeriodId={periodToAdd}
        />
      </MainContent>
      <LeaveModalWindow
        type={LeaveModalEnum.DELETE}
        onSave={formik.handleSubmit}
        onDelete={() => onDeleteSteeringAlgorithm()}
        onClose={() => onShow(false)}
        show={show}
        onHide={() => onShow(false)}
      />
    </form>
  );
}

export { SteeringAlgorithmsEdit };
