import { defineMessages } from "react-intl";

export default defineMessages({
  required : {
    id             : "ValidationMessages.required",
    defaultMessage : "This field is required",
  },
  email : {
    id             : "ValidationMessages.email",
    defaultMessage : "Not valid email",
  },
  passwordNotMatch : {
    id             : "ValidationMessages.passwordNotMatch",
    defaultMessage : "Passwords don’t match",
  },
  moreThan : {
    id             : "ValidationMessages.moreThan",
    defaultMessage : "Value must be more than {number}",
  },
  maxLength : {
    id             : "ValidationMessages.maxLength",
    defaultMessage : "Max length are {number} charts",
  },
  minLength : {
    id             : "ValidationMessages.minLength",
    defaultMessage : "Min length is {number} charts",
  },
  maxValue : {
    id             : "ValidationMessages.maxValue",
    defaultMessage : "Maximum value is {number}",
  },
  minValue : {
    id             : "ValidationMessages.minValue",
    defaultMessage : "Minimum value is {number}",
  },
  minArrayLength : {
    id             : "ValidationMessages.minArrayLength",
    defaultMessage : "Select at least {number} item(s)",
  },
  invalidDateRange : {
    id             : "ValidationMessages.invalidDateRange",
    defaultMessage : "Invalid date range",
  },
  integer : {
    id             : "ValidationMessages.integer",
    defaultMessage : "Value must be integer",
  },
  typeNotFound : {
    id             : "ValidationMessages.typeNotFound",
    defaultMessage : "Rule type is not found",
  },
  stateNotFound : {
    id             : "ValidationMessages.typeNotFound",
    defaultMessage : "Rule state is not found",
  },
  invalidHourRange : {
    id             : "ValidationMessages.invalidHourRange",
    defaultMessage : "Invalid hour range",
  },
  uniqueNames : {
    id             : "ValidationMessages.uniqueNames",
    defaultMessage : "Names Must Be Uniques",
  },
  serverNames : {
    id             : "ValidationMessages.serverNames",
    defaultMessage : "Select another name than {name}, this name is used by the server",
  },
  serverSuffix : {
    id             : "ValidationMessages.serverSuffix",
    defaultMessage : "You name can't end with {suffix}, this suffix is used by the server",
  },
  invalidCharacters: {
    id             : "ValidationMessages.invalidCharacters",
    defaultMessage : "Only alphanumeric characters, [-] and [_] are allowed.",
  },
  invalidDates: {
    id             : "ValidationMessages.invalidDates",
    defaultMessage : "Dates must not be identical",
  }
});
