export const Floor = (props: {
  height: number;
  width: number;
  opacity: number;
  startingFloorX: number;
  startingFloorY: number;
}) => {
  return (
    <>
      <mesh position={[props.startingFloorY, 0, props.startingFloorX]}>
        <boxGeometry
          attach="geometry"
          args={[props.height, 0.1, props.width]}
        />
        <meshStandardMaterial attach="material" color="#0f0" />
      </mesh>
      <mesh
        receiveShadow
        position={[props.startingFloorY, 0.1, props.startingFloorX]}
      >
        <boxGeometry
          attach="geometry"
          args={[props.height, 0.1, props.width]}
        />
        <shadowMaterial transparent opacity={props.opacity} />
      </mesh>
    </>
  );
};
