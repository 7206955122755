import React from "react";
import styled from "components/SimulationDropItem/styled.module.scss";
import Line from "components/SimulationDropItem/Line";
import { useIntl } from "react-intl";
import StructuresRenderListMessages from "./StructuresRenderListMessages";

const GrasslandYieldPropertiesRenderList = ({
  selected,
}: {
  selected: any;
}) => {
  const intl = useIntl();
  return (
    <ul className={styled.ul}>
      <Line
        title={intl.formatMessage(StructuresRenderListMessages.raygrass)}
        value={`${selected.raygrass_frac} %`}
      />
      <Line
        title={intl.formatMessage(StructuresRenderListMessages.fetuque)}
        value={`${selected.fetuque_frac} %`}
      />
      <Line
        title={intl.formatMessage(StructuresRenderListMessages.dactyle)}
        value={`${selected.dactyle_frac} %`}
      />
      <Line
        title={intl.formatMessage(StructuresRenderListMessages.treffle)}
        value={`${selected.treffle_frac} %`}
      />
    </ul>
  );
};

export default GrasslandYieldPropertiesRenderList;
