import { array, lazy, number, object, string } from "yup";
import { IntlShape } from "react-intl";
import validationMessages from "components/common/ValidationMessages";

const rows_water_balance_schema = (intl: IntlShape) =>
  lazy((arr) => {
    return array()
      .of(
        object().shape({
          ...(arr.length &&
            Object.hasOwn(arr[0], "weather_dataset_id") && {
              weather_dataset_id: number()
                .nullable()
                .required(intl.formatMessage(validationMessages.required)),
            }),
          ...(arr.length &&
            Object.hasOwn(arr[0], "production_dataset_id") && {
              production_dataset_id: number()
                .nullable()
                .required(intl.formatMessage(validationMessages.required)),
            }),
            ...(arr.length &&
              Object.hasOwn(arr[0], "production_dataset_id") && {
                production_dataset_id: number()
                  .nullable()
                  .required(intl.formatMessage(validationMessages.required)),
              }),
        })
      )
      .min(
        1,
        intl.formatMessage(validationMessages.minArrayLength, { number: 1 })
      );
  });

const BatchesWaterBalanceSchema = (intl: IntlShape) =>
  object().shape({
    name: string()
      .max(50, intl.formatMessage(validationMessages.maxLength, { number: 50 }))
      .required(intl.formatMessage(validationMessages.required)),
    base_simulation_water_balance_id: number()
      .nullable()
      .required(intl.formatMessage(validationMessages.required)),
    frequency: string().required(
      intl.formatMessage(validationMessages.required)
    ),
    interests: array()
      .of(string())
      .min(
        1,
        intl.formatMessage(validationMessages.minArrayLength, { number: 1 })
      ),
    rows: rows_water_balance_schema(intl),
  });

export default BatchesWaterBalanceSchema;
