import { defineMessages } from "react-intl";

export default defineMessages({
  simulationSetting: {
    id: "SimulationResultBodyMessages.simulationSetting",
    defaultMessage: "Simulation Setting",
  },
  simulationInProgress: {
    id: "SimulationResultBodyMessages.simulationInProgress",
    defaultMessage: "Simulation In Progress...",
  },
  simulationResults: {
    id: "SimulationResultBodyMessages.simulationResults",
    defaultMessage: "Simulation Results",
  },
  inProgress: {
    id: "SimulationResultBodyMessages.inProgress",
    defaultMessage: "In Progress: Stage {value} of 2",
  },
  periodSimulation: {
    id: "SimulationResultBodyMessages.periodSimulation",
    defaultMessage: "Period of simulation",
  },
  periodsSimulation: {
    id: "SimulationResultBodyMessages.periodsSimulation",
    defaultMessage: "Periods of simulation",
  },
  irradianceRefZone: {
    id: "SimulationResultBodyMessages.irradianceRefZone",
    defaultMessage: "Irradiance Control Zone ({unit})",
  },
  betweenPanels: {
    id: "SimulationResultBodyMessages.betweenPanels",
    defaultMessage: "Irradiance Between Panels ({unit})",
  },
  underPanels: {
    id: "SimulationResultBodyMessages.underPanels",
    defaultMessage: "Irradiance Under Panels ({unit})",
  },
  agrilPV: {
    id: "SimulationResultBodyMessages.agrilPV",
    defaultMessage: "Irradiance AgrilPV zone ({unit})",
  },
  heterogeneity: {
    id: "SimulationResultBodyMessages.heterogeneity",
    defaultMessage: "Heterogeneity",
  },
  EtpRefZone: {
    id: "SimulationResultBodyMessages.EtpRefZone",
    defaultMessage: "ETP Control Zone ({unit})",
  },
  EtpUnderPanels: {
    id: "SimulationResultBodyMessages.EtpUnderPanels",
    defaultMessage: "ETP Under Panels ({unit})",
  },
  EtpBetweenPanels: {
    id: "SimulationResultBodyMessages.EtpBetweenPanels",
    defaultMessage: "ETP Between Panels ({unit})",
  },
  EtpAgriPVZone: {
    id: "SimulationResultBodyMessages.EtpAgriPVZone",
    defaultMessage: "ETP AgriPV Zone ({unit})",
  },
  simulationFrequency: {
    id: "SimulationResultBodyMessages.simulationFrequency",
    defaultMessage: "Simulation Frequency",
  },
  maxScaleValue: {
    id: "SimulationResultBodyMessages.maxScaleValue",
    defaultMessage: "Max Scale Value",
  },
  resolution: {
    id: "SimulationResultBodyMessages.resolution",
    defaultMessage: "Resolution",
  },
  hintLegend: {
    id: "SimulationResultBodyMessages.hintLegend",
    defaultMessage: "Hint: Try to click on legend's values",
  },
  hintChartToPng: {
    id: "SimulationResultBodyMessages.hintChartToPng",
    defaultMessage: "Download chart in png",
  },
  hintTableToPng: {
    id: "SimulationResultBodyMessages.hintTableToPng",
    defaultMessage: "Download table in png",
  },
  hintChartToCsv: {
    id: "SimulationResultBodyMessages.hintChartToCsv",
    defaultMessage: "Download chart's data in csv",
  },
  hintTableToCsv: {
    id: "SimulationResultBodyMessages.hintTableToCsv",
    defaultMessage: "Download table's data in csv",
  },
  dailyDataAllPeriod: {
    id: "SimulationResultBodyMessages.dailyDataAllPeriod",
    defaultMessage: "Daily Data from all Periods",
  },
  dataPerPeriod: {
    id: "SimulationResultBodyMessages.dataPerPeriod",
    defaultMessage: "Data per Stage",
  },
  irrPerPeriod: {
    id: "SimulationResultBodyMessages.irrPerPeriod",
    defaultMessage: "Irradiance per Stage",
  },
  ETPPerPeriod: {
    id: "SimulationResultBodyMessages.ETPPerPeriod",
    defaultMessage: "ETM per Stage",
  },
  ETMPerMonth: {
    id: "SimulationResultBodyMessages.ETMPerMonth",
    defaultMessage: "ETM per Month",
  },
  ProdPerPeriod: {
    id: "SimulationResultBodyMessages.ProdPerPeriod",
    defaultMessage: "Production per Stage",
  },
  ProdPerMonth: {
    id: "SimulationResultBodyMessages.ProdPerMonth",
    defaultMessage: "Production per Month",
  },
  watPerPeriod: {
    id: "SimulationResultBodyMessages.watPerPeriod",
    defaultMessage: "Water deficit per Stage",
  },
  irrOfPeriod: {
    id: "SimulationResultBodyMessages.irrOfPeriod",
    defaultMessage: "Irradiance of ':period_name' Stage",
  },
  ETPOfPeriod: {
    id: "SimulationResultBodyMessages.ETPOfPeriod",
    defaultMessage: "ETP of ':period_name' Stage",
  },
  ProdOfPeriod: {
    id: "SimulationResultBodyMessages.ProdOfPeriod",
    defaultMessage: "Production of ':period_name' Stage",
  },
  irrAllPeriods: {
    id: "SimulationResultBodyMessages.irrAllPeriods",
    defaultMessage: "Daily Irradiance of all Stages",
  },
  ETPAllPeriods: {
    id: "SimulationResultBodyMessages.ETPAllPeriods",
    defaultMessage: "Daily ETP of all Stages",
  },
  ProdAllPeriods: {
    id: "SimulationResultBodyMessages.ProdAllPeriods",
    defaultMessage: "Daily Production of all Stages",
  },
  prodAgriPV: {
    id: "SimulationResultBodyMessages.prodAgriPV",
    defaultMessage: "Production (kWh/kWc)",
  },
  irrDistribution: {
    id: "SimulationResultBodyMessages.irrDistribution",
    defaultMessage: "Irradiance Distribution In Zone Agri",
  },
  irradianceFraction: {
    id: "SimulationResultBodyMessages.irradianceFraction",
    defaultMessage: "Irradiance Fraction (%)",
  },
  irrandianceDailyPAR: {
    id: "SimulationResultBodyMessages.irrandianceDailyPAR",
    defaultMessage: "Daily PAR (Wh/m²)",
  },
  irradianceFractionHelp: {
    id: "SimulationResultBodyMessages.irradianceFractionHelp",
    defaultMessage:
      "The irradiance fraction corresponds to the share of the total emitted radiation received by the crop.",
  },
  irradianceFractionSubTitle: {
    id: "SimulationResultBodyMessages.irradianceFractionSubTitle",
    defaultMessage: "in agriPV zone",
  },
  meanDailyPAR: {
    id: "SimulationResultBodyMessages.meanDailyPAR",
    defaultMessage: "Mean daily PAR (Wh/m2)",
  },
  meanDailyPARHelp: {
    id: "SimulationResultBodyMessages.meanDailyPARHelp",
    defaultMessage:
      "Photosynthetically Active Radiation defined as electromagnetic radiation in the spectral range of 400 nm to 700 nm that photosynthetic organisms can use in the process of photosynthesis to fix carbon in CO2 in carbohydrates. The PAR corresponds to 48% of the luminous flux emitted by the sun.",
  },
  meanDailyPARSubTitle: {
    id: "SimulationResultBodyMessages.meanDailyPARSubTitle",
    defaultMessage: "in agriPV zone",
  },
  heterogeneityTh: {
    id: "SimulationResultBodyMessages.heterogeneityTh",
    defaultMessage: "Heterogeneity (%)",
  },
  heterogeneityHelp: {
    id: "SimulationResultBodyMessages.heterogeneityHelp",
    defaultMessage:
      "It is an indicator which makes it possible to reflect the heterogeneity of the radiation induced by the chosen solution. If heterogeneity = 0, then the radiation received is perfectly homogeneous.",
  },
  ETPAgriPVZone: {
    id: "SimulationResultBodyMessages.ETPAgriPVZone",
    defaultMessage: "ETM ({unite})",
  },
  ETPAgriPVZoneHelp: {
    id: "SimulationResultBodyMessages.ETPAgriPVZoneHelp",
    defaultMessage:
      "Potential evapotranspiration (ETP) or reference evapotranspiration (ETo), can be defined as the sum of the transpiration of the plant cover and the evaporation of the soil which could occur in the event of an unconstrained water supply for a given area. low, continuous and homogeneous plant cover without any limitation (nutritional, physiological or pathological).",
  },
  ETPAgriPVZoneSubTitle: {
    id: "SimulationResultBodyMessages.ETPAgriPVZoneSubTitle",
    defaultMessage: "in agriPV zone",
  },
  agriPVZone: {
    id: "SimulationResultBodyMessages.agriPVZone",
    defaultMessage: "in agriPV zone",
  },
  controlZone: {
    id: "SimulationResultBodyMessages.controlZone",
    defaultMessage: "in control zone",
  },
  waterDeficitHelp: {
    id: "SimulationResultBodyMessages.waterDeficitHelp",
    defaultMessage:
      "The water deficit (or balance) is calculated by the difference between precipitation and potential evapotranspiration over a given period.",
  },
  waterDeficit: {
    id: "SimulationResultBodyMessages.waterDeficit",
    defaultMessage: "Water deficit (mm)",
  },
  waterDeficitSubTitle: {
    id: "SimulationResultBodyMessages.waterDeficitSubTitle",
    defaultMessage: "in agriPV zone",
  },
  mean: {
    id: "SimulationResultBodyMessages.mean",
    defaultMessage: "Mean",
  },
  productionSunStracking: {
    id: "SimulationResultBodyMessages.productionSunStracking",
    defaultMessage: "Production sun tracking",
  },
  productionAgriPV: {
    id: "SimulationResultBodyMessages.productionAgriPV",
    defaultMessage: "Production Agri PV",
  },
  frostStress: {
    id: "SimulationResultBodyMessages.frostStress",
    defaultMessage: "Frost stress (days)",
  },
  heatStress: {
    id: "SimulationResultBodyMessages.heatStress",
    defaultMessage: "Heat stress (days)",
  },
  days: {
    id: "SimulationResultBodyMessages.days",
    defaultMessage: "Days",
  },
  titleThermalStressFreeze: {
    id: "SimulationResultBodyMessages.titleThermalStress",
    defaultMessage: "Days of frost",
  },
  titleThermalStressHot: {
    id: "SimulationResultBodyMessages.titleThermalStress",
    defaultMessage: "Days of heat stress",
  },
  frostStressHelp: {
    id: "SimulationResultBodyMessages.frostStressHelp",
    defaultMessage: "The number of days with minimum temperature Tx < 0°C",
  },
  heatStressHelp: {
    id: "SimulationResultBodyMessages.heatStressHelp",
    defaultMessage: "The number of days with maximum temperature Tx > 25°C",
  },
  AgriPvWaterDeficit: {
    id: "SimulationResultBodyMessages.AgriPvWaterDeficit",
    defaultMessage: "AgriPV Zone",
  },
  refZoneWaterDeficit: {
    id: "SimulationResultBodyMessages.refZoneWaterDeficit",
    defaultMessage: "Control Zone",
  },
});
