import { useIntl } from "react-intl";
import parametersInterestMessages from "components/ParametersInterest/ParametersInterestMessages";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getBatchesWaterBalanceParameters } from "store/batches_water_balance/selectors";
import { getBatchWaterBalanceParametersOfInterest } from "store/batches_water_balance/actions";

export type IInterestNameVariantsWaterBalance =
  | "panel_height"
  | "lower_table_tip_height"
  | "panel_opacity"
  | "azimuth"
  | "panel_size"
  | "panel_tilt"
  | "panel_max_tilt"
  | "initial_offset"
  | "panels_number"
  | "panels_gap"
  | "field_size"
  // | "weather_dataset_id"
  // | "ETP_dataset_id"
  // | "production_dataset_id"
  | "universal_dataset_id"
  | "crop_id"
  | "soil_information_id"
  | "steering_algorithm_id";

export interface IInterestParamsWaterBalanceItem {
  category:
    | "structures"
    | "crops"
    | "datasets"
    | "steering_algorithms"
    | "soil_informations";
  created_at: string;
  id: number;
  name: IInterestNameVariantsWaterBalance;
  type:
    | "input"
    | "point"
    | "crop" /*"production_dataset" | "weather_dataset" | "ETP_dataset" | */
    | "universal_dataset"
    | "soil_information"
    | "steering_algorithm";
  updated_at: string;
  label: string;
}

export function useWaterBalanceParamsList() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const list = useSelector(getBatchesWaterBalanceParameters);

  useEffect(() => {
    dispatch(getBatchWaterBalanceParametersOfInterest());
  }, []);

  const formatList: IInterestParamsWaterBalanceItem[] = list.map(
    (item: Omit<IInterestParamsWaterBalanceItem, "label">) => ({
      ...item,
      label: intl.formatMessage(parametersInterestMessages[item.name]),
    })
  );

  return formatList;
}
